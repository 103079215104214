import React, { useState, useContext } from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import HomePage from "./HomePage";
import { loginContext } from "../../hooks/customHooks/Context";
import './LandingPage.css';

const Video = styled("video")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
});

const Image = styled("img")({
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "cover",
});

export default function LandingPage({ isLoggedIn, setIsLoggedIn }) {
    const [showDecision, setShowDecision] = useState(false);
    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const { setShowLoginPage, isAuthenticated } = useContext(loginContext);

    const handleExploreClick = () => {
        if (isAuthenticated) {
            setShowDecision(true);
        } else {
            setShowLoginPage(true);
        }
    };

    const handleCloseLoginDialog = () => {
        setShowLoginDialog(false);
    };

    if (showDecision) {
        return <HomePage />;
    }

    return (
        <>
            <Box>
                {/* Hero Section - Increased Height */}
                <Box sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    filter: 'contrast(1)',
                    backgroundImage: 'url("../Images/Logo/TechScaler_HeaderImage.png")',
                    pt: { xs: 5, md: 20 },
                    pl: { xs: 2, md: 10 },
                    pr: { xs: 2, md: 0 },
                    pb: { xs: 5, md: 15 },
                    textAlign: { xs: "center", md: "left" },
                    height: { xs: "500px", md: "700px" },
                }}>
                    <Box sx={{ width: { xs: "100%", md: "40%" } }}>
                        <Typography variant="body1" sx={{ color: "#ffffff", fontSize: { xs: 14, md: 18 } }}>
                            A fast and reliable way to choose your next markets
                        </Typography>
                        <Typography variant="h4" sx={{
                            color: "#ffffff", fontWeight: 600, whiteSpace: "pre-line", fontSize: { xs: 30, md: 30 },
                        }}>
                            Scale with confidence.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#ffffff", fontSize: { xs: 14, md: 18 } }}>
                            Picking the wrong markets can be an existential threat to your business. By analyzing thousands of data points from global markets, our decision-making platform leads you through a streamlined process to identify the ideal markets for your technology.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#ffffff", fontSize: { xs: 14, md: 18 } }}>
                            <strong> Anti-biased:</strong> Remove the guesswork by relying on a clear, science-based decision-making process optimized for technology owners.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#ffffff", fontSize: { xs: 14, md: 18 } }}>
                            <strong> Data-Driven:  </strong> Access curated market data tailored to your technology’s scaling needs in every country worldwide.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#ffffff", fontSize: { xs: 14, md: 18 } }}>
                            <strong> Future-Proof:  </strong>Stay ahead of the curve with real-time insights and trends that help you anticipate market shifts and make proactive, informed decisions.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleExploreClick}
                            sx={{
                                bgcolor: '#FFA62B',
                                color: '#ffffff',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                padding: 1,
                                marginTop: 4,
                                '&:hover': { bgcolor: '#FFA62B', color: '#ffffff' },
                            }}
                        >
                            Join now
                        </Button>
                    </Box>
                </Box>

                {/* Scalable Decision-Making Platform Section */}
                <Box>
                    <Typography variant="h4" sx={{ color: "#000000", fontWeight: 500, whiteSpace: "pre-line", fontSize: { xs: 30, md: 40 }, m: 5 }}>
                        Scaling decisions are costly and extremely risky. {"\n"} Navigate them with ease.
                    </Typography>
                </Box>

                {/* First Split Content */}
                <Box sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 10
                }}>
                    <Box sx={{ width: { xs: "100%", md: "50%" }, p: 10 }} >
                        <Typography variant="body1" sx={{ color: "#000000", fontWeight: 500, fontSize: { xs: 25, md: 25 }, m: 0, textAlign: 'left' }}>
                            A digital decision making platform for Green Tech startups
                        </Typography>

                        <Typography variant="body1" sx={{ color: "#000000", fontSize: { xs: 18, md: 18 }, textAlign: 'left' }}>
                            Scaling clean tech startups involves countless risks and high stakes at every turn. Misjudging growth potential, misallocating resources, or —worst of all— entering a wrong market only to face a costly market exit a few months later can cripple your business. The pressure to get this right is immense, but even the most seasoned leaders can stumble without the right data and tools. If you're tired of second-guessing your decisions and want to avoid these costly mistakes, it’s time for a data-driven approach. xScaler offers the clarity and confidence you need with a streamlined decision-making platform designed to help you navigate these challenges and scale successfully.
                        </Typography>
                    </Box>

                    <Box sx={{ width: { xs: "100%", md: "50%" }, textAlign: "center" }}>
                        <img src="../Images/Logo/bargraph.png" alt="barchart" style={{ width: '100%', height: 'auto' }} />
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 10
                }}>
                    <Box sx={{ width: { xs: "100%", md: "50%" }, textAlign: "center" }}>
                        <img src="../Images/Logo/Framing.png" alt="barchart" style={{ width: '100%', height: 'auto' }} />
                    </Box>

                    <Box sx={{ width: { xs: "100%", md: "50%", }, p: 10 }} >
                        <Typography variant="body1" sx={{ color: "#000000", fontWeight: 500, fontSize: { xs: 25, md: 25 }, m: 0, textAlign: 'left', }}>
                            Scale your business,and your impact.
                        </Typography>

                        <Typography variant="body1" sx={{ color: "#000000", fontSize: { xs: 18, md: 18 }, textAlign: 'left' }}>
                            Entering the right markets is not just about revenue goals. It ensures your technology can can truly thrive and contributing to a more sustainable future. With precise data and clear insights, you can drive growth while maximizing your positive impact. xScaler provides the tools to help you navigate this journey, empowering you to scale your business and make a lasting difference.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
