import React from "react";
import { Box, Button } from "@mui/material";

function CustomMinMaxSwitch({ isChecked, onChange, requirementId, isDisabled }) {
    const handleMinClick = () => {
        if (onChange && !isDisabled) onChange(requirementId, false);
    };

    const handleMaxClick = () => {
        if (onChange && !isDisabled) onChange(requirementId, true);
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: isDisabled ? "#EDE7E3" : "#489FB5", // Light gray if disabled, blue otherwise
                borderRadius: "8px",
                padding: "4px",
                width: "fit-content",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                height: "25px", // Adjusted height for better button fit
            }}
        >
            <Button
                onClick={handleMinClick}
                disabled={isDisabled}
                sx={{
                    backgroundColor: isDisabled
                        ? "transperant" // Button background when disabled
                        : isChecked
                            ? "transparent" // Active state
                            : "#82C0CC", // Inactive state
                    color: isDisabled
                        ? "#7f7f7f" // Text color when disabled
                        : isChecked
                            ? "#ffffff"
                            : "#ffffff",
                    textTransform: "none",
                    '&:hover': {
                        backgroundColor: isDisabled ? "#B2ADA9" : "#82C0CC",
                    },
                    height: "23px",
                    padding: "0 8px",
                }}
            >
                Min
            </Button>
            <Button
                onClick={handleMaxClick}
                disabled={isDisabled}
                sx={{
                    marginLeft: "8px",
                    backgroundColor: isDisabled
                        ? "#B2ADA9" // Button background when disabled
                        : !isChecked
                            ? "transparent" // Inactive state
                            : "#82C0CC", // Active state
                    color: !isChecked
                        ? "#ffffff"
                        : "#ffffff",
                    textTransform: "none",
                    '&:hover': {
                        backgroundColor: isDisabled ? "#B2ADA9" : "#82C0CC",
                    },
                    height: "23px",
                    padding: "0 8px",
                }}
            >
                Max
            </Button>
        </Box>
    );
}

export default CustomMinMaxSwitch;
