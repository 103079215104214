import React, { Fragment, useContext, useRef, useState } from 'react';
import { loginContext } from '../../hooks/customHooks/Context';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Profile from './Profile';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import { CardContextProvider } from '../../hooks/customHooks/CardContextProvider';

const Navbar = () => {
    const { isAuthenticated, setShowLoginPage, setShowOverviewPage, showOverviewPage, logout } = useContext(loginContext);
    const navRef = useRef();
    const navigate = useNavigate();
    const [openProfile, setOpenProfile] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const toggleNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    };
    const handleOpenProfile = () => {
        setOpenProfile(true);
    };

    const handleCloseProfile = () => {
        setOpenProfile(false);
    };
    const handleLogoClick = () => {
        setShowOverviewPage(true);
    };
    const handleContactClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const isPopoverOpen = Boolean(anchorEl);

    const openFAQ = () => {
        window.open('/faqs', '_blank');
    };

    return (
        <Fragment>
            <header>
                <div className='logo' onClick={handleLogoClick}>
                    <img
                        src="../Images/Logo/TechScaler_LogoTextRight.png"
                        alt="xScaler"
                        className="logo"
                    />
                </div>
                <nav ref={navRef} >
                    <Link
                        sx={{
                            color: '#16697A',
                            textTransform: 'none',
                            margin: '4px',
                            padding: '6px 16px',
                            textDecoration: 'none',
                            fontWeight: 'bold',
                            display: 'inline-block',
                            '&:hover': { color: '#16697A' },
                        }}
                        onClick={openFAQ}
                    >
                        FAQ
                    </Link>
                    <Link
                        type="submit"
                        sx={{
                            color: '#16697A',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            margin: '4px',
                            padding: '6px 16px',
                            textDecoration: 'none',
                            display: 'inline-block',
                            '&:hover': { color: '#16697A' },
                        }}
                        onClick={handleContactClick}
                    >
                        Contact
                    </Link>
                    <Popover
                        open={isPopoverOpen}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Typography sx={{ padding: 2 }}>
                            Please mail us at <strong>support@xscaler.org</strong>
                        </Typography>
                    </Popover>

                    {/* <a href='/#'>Team</a>
                    <a href='/#'>Guide</a>
                    <a href='/#'>Languages</a> */}
                    {isAuthenticated && <Link type="submit" sx={{
                        color: '#16697A',
                        textTransform: 'none',
                        margin: '4px',
                        fontWeight: 'bold',
                        padding: '6px 16px',
                        textDecoration: 'none',
                        display: 'inline-block',
                        '&:hover': { color: '#16697A' },
                    }} onClick={handleOpenProfile} >
                        Profile
                    </Link>
                    }
                    {isAuthenticated ? (
                        <>
                            {!showOverviewPage && (
                                <Link type="submit" sx={{
                                    color: '#16697A',
                                    textTransform: 'none',
                                    margin: '4px',
                                    padding: '6px 16px',
                                    textDecoration: 'none',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    '&:hover': { color: '#16697A' },
                                }}
                                    onClick={() => {
                                        setShowOverviewPage(true);
                                    }}
                                >
                                    Overview
                                </Link>
                            )}
                            <Button
                                variant="contained"

                                sx={{
                                    bgcolor: '#FFA62B',
                                    color: '#313638',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    '&:hover': { bgcolor: '#FFA62B', color: '#ffffff' },
                                }}
                                onClick={() => {
                                    logout();
                                    setShowOverviewPage(true);
                                    setShowLoginPage(false);
                                }}
                            >
                                Logout
                            </Button>
                            <button
                                className="nav-btn nav-close-btn"
                                onClick={toggleNavbar}
                            >
                                <CloseIcon />
                            </button>
                        </>
                    ) : (
                        <>
                            <Button
                                className="loginButton"
                                variant="contained"
                                sx={{
                                    bgcolor: '#FFA62B',
                                    color: '#313638',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    '&:hover': { bgcolor: '#FFA62B', color: '#ffffff' },
                                }}
                                onClick={() => {
                                    setShowOverviewPage(false);
                                    setShowLoginPage(true);
                                }}
                            >
                                Login
                            </Button>
                            <button
                                className="nav-btn nav-close-btn"
                                onClick={toggleNavbar}
                            >
                                <CloseIcon />
                            </button>
                        </>
                    )}

                </nav>
                <button
                    className="nav-btn"
                    onClick={toggleNavbar}
                >
                    <MenuIcon />
                </button>
            </header>
            <Divider sx={{ height: '1px', margin: '20px 0 0 0', backgroundColor: '#116272' }} />
            <Dialog open={openProfile} onClose={handleCloseProfile} fullWidth maxWidth="md"
                PaperProps={{
                    sx: { padding: '0px', margin: '0px' }
                }}>
                <CardContextProvider>
                    <Profile />
                </CardContextProvider>
            </Dialog>
        </Fragment>

    );
}

export default Navbar;