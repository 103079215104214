import React, { useContext, useState, useEffect } from 'react';
import { loginContext } from '../../hooks/customHooks/Context';
import { CardContextProvider } from '../../hooks/customHooks/CardContextProvider';
import { ProjectContextProvider } from '../../hooks/customHooks/ProjectContexProvider';
import Login from '../header/Login';
import OverviewPage from './OverviewPage';
import DecisionSteps from './DecisionSteps';
import CompanyProfile from '../header/CompanyProfile';
import { ProjectService } from '../../utils/ApiDataHandler';
import CustomLoadingSpinner from '../../customComponents/CustomLoadingSpinner';

function HomePage() {
    const { isAuthenticated, showOverviewPage, setCompanyData } = useContext(loginContext);
    const [isCompanyDataFilled, setIsCompanyDataFilled] = useState(false);
    const [isProfileSubmitted, setIsProfileSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkCompanyData = async () => {
            try {
                const companyData = await ProjectService.fetchCompanyDetails();
                setCompanyData(companyData.data)
                setIsCompanyDataFilled(companyData.success)
            } catch (error) {
                console.error('Error checking company data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (isAuthenticated) {
            checkCompanyData();
        }
    }, [isAuthenticated]);

    let content;
    if (isLoading && isAuthenticated) {
        content = <div>Loading...</div>;
    } else if (isAuthenticated) {
        if (!isCompanyDataFilled && !isProfileSubmitted) {
            content = <CompanyProfile onSubmitSuccess={() => setIsProfileSubmitted(true)} />;
        } else {
            content = (
                <ProjectContextProvider>
                    {showOverviewPage ? <OverviewPage /> : <DecisionSteps />}
                </ProjectContextProvider>
            );
        }
    } else {
        content = <Login />;
    }

    return (
        <CardContextProvider>
            {content}
        </CardContextProvider>
    );
}

export default HomePage;
