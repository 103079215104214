import { Button, Slider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect, Fragment } from 'react';
import { CustomCard } from '../../customComponents/CustomCard';
import { cardContext, projectContext } from '../../hooks/customHooks/Context';
import CustomStackedBarChart from '../../customComponents/CustomStackedBarChart';
import { ProjectService } from '../../utils/ApiDataHandler';
import { chartColors, getNameByRequirementId } from "../../utils/ProjectDataHandler";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import "./RequirementWeighting.css";

const RobustnessAnalysis = (props) => {
    const { selectedProjectData } = useContext(projectContext);
    const [evaluationData, setEvaluationData] = useState(null);
    const [seriesData, setSeriesData] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [isWorldwide, setIsWorldwide] = useState(false);
    const [tempReqWeighting, setTempReqWeighting] = useState([]);
    const { setHeading, setCardWidth, setTextAlign, setDescription } = useContext(cardContext);

    useEffect(() => {
        setHeading('How robust is the result?');
        setDescription('You can check the robustness of the results of your decision analysis by changing the weighting of the requirements you have chosen. The result is more robust the less the order of the regions changes with weighting changes.');
        setCardWidth("100%");
        setTextAlign("left");
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);

    const onClickBack = () => {
        props.handleBack();
    };

    const onClickNext = () => {
        props.handleNext();
    };

    const marks = [
        { value: 1, label: 1 },
        { value: 10, label: 10 },
    ];

    const getEvaluation = async (selectedProjectData) => {
        try {
            const result = await ProjectService.fetchEvaluation(selectedProjectData.id);
            setEvaluationData(result);
            setTempReqWeighting(
                result.project_requirements.reduce((acc, req) => {
                    acc[req.requirement_id] = req.weighting;
                    return acc;
                }, {})
            );
            processEvaluationData(result, isWorldwide);
        } catch (error) {
            console.error('Error fetching evaluation:', error);
        }
    };

    const processEvaluationData = (data, isWorldwide) => {
        const series = [];
        const countries = [];

        const totalWeighting = data.project_requirements.reduce((sum, req) => sum + parseFloat(req.weighting), 0);

        if (!isWorldwide) {
            const top10Data = data.all_country_result;
            const allCountryData = data.all_country_result;
            Object.keys(allCountryData).forEach((stackKey) => {
                const stackData = allCountryData[stackKey];

                const requirement = data.project_requirements.find(req => req.requirement_id === Number(stackKey));
                const weightingPercentage = (parseFloat(requirement.weighting) / totalWeighting).toFixed(2);
                // Step 4: Initialize the weightedData array for IDs 1-200
                const weightedData = Object.values(allCountryData[stackKey]).map(value => (value * weightingPercentage).toFixed(2));

                series.push({
                    name: getNameByRequirementId(data.project_requirements, stackKey),
                    data: weightedData,
                });

                if (countries.length === 0) {
                    countries.push(...Object.keys(allCountryData[stackKey]));
                }
            });
        } /*else {
            const selectedData = data.before_applied_percentage_requirments_value_selected_country;

            Object.keys(selectedData).forEach((stackKey) => {
                const requirement = data.project_requirements.find(req => req.requirement_id === Number(stackKey));

                const weightingPercentage = (parseFloat(requirement.weighting) / totalWeighting).toFixed(2);
                const weightedData = Object.values(selectedData[stackKey]).map(value => (value * weightingPercentage).toFixed(2));

                series.push({
                    name: getNameByRequirementId(data.project_requirements, stackKey),
                    data: weightedData,
                });

                if (countries.length === 0) {
                    countries.push(...Object.keys(selectedData[stackKey]));
                }
            });
        }*/

        setSeriesData(series);
        setCountryList([...new Set(countries)]);
    };

    const handleSliderChange = (requirementId, newValue) => {
        setTempReqWeighting(prevState => ({
            ...prevState,
            [requirementId]: newValue,
        }));

        const updatedRequirements = evaluationData.project_requirements.map(req =>
            req.requirement_id === requirementId ? { ...req, weighting: newValue } : req
        );

        const updatedEvaluationData = {
            ...evaluationData,
            project_requirements: updatedRequirements,
        };

        processEvaluationData(updatedEvaluationData, isWorldwide);
    };


    const handleToggle = () => {
        setIsWorldwide(!isWorldwide);
        if (evaluationData) {
            processEvaluationData(evaluationData, !isWorldwide);
        }
    };

    useEffect(() => {
        if (selectedProjectData?.id) {
            getEvaluation(selectedProjectData);
        }
    }, [selectedProjectData]);
    return (
        <Fragment>
            <Grid container direction="row" spacing={2} sx={{ minHeight: "48vh", justifyContent: 'space-around' }}>
                {selectedProjectData?.requirements && (
                    <Grid item xs={4} sx={{ fontSize: '12px' }} className="requirement-slider">
                        {selectedProjectData.requirements.sort((a, b) => a.requirement_id - b.requirement_id)
                            .map((requirement, index) => {
                                return (
                                    <Grid container direction="row" key={requirement.requirement_id} sx={{ marginBottom: '-3px', padding: ' 18px 24px 0 0', textAlign: 'left', justifyContent: 'space-between' }}>
                                        <Grid
                                            item
                                            xs={5}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                width: 230,
                                            }}
                                            gap={2}
                                        >
                                            <Brightness1Icon
                                                sx={{
                                                    color: chartColors[index % chartColors.length],
                                                    textAlign: 'left',
                                                    height: 16,
                                                }}
                                            />
                                            {requirement.requirement_name}
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Slider
                                                value={tempReqWeighting[requirement.requirement_id] || requirement.weighting}
                                                step={1}
                                                min={1}
                                                max={10}
                                                valueLabelDisplay="on"
                                                onChange={(event, newValue) => {
                                                    handleSliderChange(requirement.requirement_id, newValue);
                                                }}
                                                sx={{
                                                    width: 130,
                                                    marginLeft: '30px',
                                                    "& .MuiSlider-thumb": {
                                                        width: 15,
                                                        height: 15,
                                                        color: "#16697A",
                                                    },
                                                    "& .MuiSlider-track": {
                                                        color: "#489FB5",
                                                    },
                                                    "& .MuiSlider-rail": {
                                                        color: "#82C0CC",
                                                    },
                                                    "& .Mui-active": {
                                                        width: 18,
                                                        height: 18,
                                                        color: "#16697A",
                                                    },
                                                    "& .MuiSlider-valueLabel": {
                                                        backgroundColor: "transparent",
                                                        color: "#16697A",
                                                        fontSize: "12px",
                                                        borderRadius: "4px",
                                                        padding: "0px 0px",
                                                    },
                                                }}
                                            // marks={marks}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                )}

                {evaluationData ? (
                    <Grid item xs={5} sx={{
                        width: '50%', overflow: 'auto', scrollbarWidth: 'thin',
                    }}>
                        {/* <Button
                            onClick={handleToggle}
                            sx={{
                                width: '250px',
                                bgcolor: '#FFA62B',
                                color: '#313638',
                                textTransform: 'none',
                                borderRadius: '5px',
                                marginBottom: '10px',
                                '&:hover': { bgcolor: '#FFA62B', color: '#313638' },
                            }}
                        >
                            {isWorldwide ? 'Selected Countries' : 'Best fitting regions worldwide'}
                        </Button>*/}
                        <CustomStackedBarChart seriesData={seriesData} countryList={countryList} customWidth={950} />
                        <p>{evaluationData.name}</p>
                    </Grid>
                ) : (
                    <Grid item xs={6}>
                        <p>Loading evaluation data...</p>
                    </Grid>
                )}
            </Grid>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', columnGap: '20px' }}>
                <Button
                    onClick={onClickBack}
                    sx={{
                        width: '100px',
                        bgcolor: '#ffffff',
                        color: '#16697A',
                        textTransform: 'none',
                        borderRadius: '5px',
                        '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                    }}
                >
                    Back
                </Button>
                <Button
                    onClick={onClickNext}
                    sx={{
                        width: '100px',
                        bgcolor: '#16697A',
                        color: '#ffffff',
                        textTransform: 'none',
                        borderRadius: '5px',
                        '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                    }}
                >
                    Next
                </Button>
            </div>
        </Fragment>
    );
};

export default CustomCard(RobustnessAnalysis);
