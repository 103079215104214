export const chartColors = [
    "#16697A",
    "#489FB5",
    "#82C0CC",
    "#A5D9CF",
    "#FFA62B",
    "#FFCB5A",
    "#CEDC4D",
    "#6E8361",
    "#AFB58C",
    "#B7CEAF",
    "#927C5F",
    "#B79546",
    "#D14E42",
    "#E19A7C",
    "#D6EDF7",
    "#C4D9E8",
    "#789CAD",
    "#D1E8C4",
    "#575757",
    "#A1BD87",
];


/**
 * Function to parse date strings in the format "dd-MM-yyyy hh:mm A" to a Date object.
 * @param {string} dateString - The date string to parse.
 * @returns {Date} - The parsed Date object.
 */
function parseCustomDate(dateString) {
    const [datePart, timePart, period] = dateString.split(' ');
    const [day, month, year] = datePart.split('-').map(Number);
    let [hours, minutes] = timePart.split(':').map(Number);

    // Adjust hours based on AM/PM
    if (period === 'PM' && hours !== 12) {
        hours += 12;
    } else if (period === 'AM' && hours === 12) {
        hours = 0;
    }

    return new Date(year, month - 1, day, hours, minutes);
}

/**
 * Function to get the project title by its ID.
 * @param {Array} projects - An array of project objects.
 * @param {number} id - The ID of the project to find.
 * @returns {string|null} - The title of the project if found, otherwise null.
 */
export function getProjectTitleData(projects, id) {
    const project = projects.find(project => project.id === id);
    return project;
}


/**
 * Function to get the most recent project based on updated_at or created_at date.
 * @param {Array} projects - An array of project objects.
 * @returns {Object|null} - The most recent project object if found, otherwise null.
 */
export function getMostRecentProject(projects) {
    if (!projects || projects.length === 0) return null;

    return projects.reduce((mostRecent, currentProject) => {
        const mostRecentDate = parseCustomDate(mostRecent.updated_at || mostRecent.created_at);
        const currentProjectDate = parseCustomDate(currentProject.updated_at || currentProject.created_at);
        // Compare the dates as numbers (milliseconds since epoch)
        return currentProjectDate > mostRecentDate ? currentProject : mostRecent;
    });
}
/**
 * Function to get the title of the most recent project.
 * @param {Array} projects - An array of project objects.
 * @returns {string|null} - The title of the most recent project if found, otherwise null.
 */
export function getMostRecentProjectId(projects) {
    const recentProject = getMostRecentProject(projects);
    return recentProject ? recentProject.id : null;
}


/**
 * Function to get sub-sectors from a specific sector based on sector ID.
 * @param {Array} sectors - An array of sector objects, each containing sub-sectors.
 * @param {number} sectorId - The ID of the sector whose sub-sectors you want to retrieve.
 * @returns {Array} - An array of sub-sector objects if the sector ID matches, otherwise an empty array.
 */
export function getSubSectorsBySectorId(sectors, sectorId) {
    if (!Array.isArray(sectors) || typeof sectorId !== 'number') return [];
    const sector = sectors.find(s => s.id === sectorId);
    return sector && sector.sub_sectors ? sector.sub_sectors : [];
}


/**
 * Function to get first sub-sectors id from a sub sectors list.
 * @param {Array} sectors - An array of sector objects, each containing sub-sectors.
 * @param {number} sectorId - The ID of the sector whose sub-sectors you want to retrieve.
 * @returns {number|null} - The ID of the first sub-sector if it exists, otherwise null.
 */
export function getSubSectorsFirstElementID(subSectors) {
    return subSectors.length > 0
        ? subSectors[0].id
        : null;
}


export function getSectorSpecificRequirements(requirements, selectedCategory, subSectorId) {
    if (selectedCategory === 1) {
        return requirements.filter(req => {
            const subSectorArray = JSON.parse(req.sub_sector_id);
            return Array.isArray(subSectorArray) && subSectorArray.includes(subSectorId);
        });

    }
    return requirements;
};


/**
 * Extracts all the requirement IDs from a project's requirements.
 * 
 * @param {Object} projectData - The project object containing requirements.
 * 
 * @returns {Array} - A list of all requirement IDs.
 */
export function getAllSelectedRequirementIds(projectData) {
    if (!projectData || !projectData.requirements) {
        return [];
    }

    return projectData.requirements.map(req => req.requirement_id);
}



export const isRequirementPresent = (requirementsList, requirementId) => {
    if (!requirementsList || !requirementsList.length) {
        return false;
    }

    return requirementsList.includes(requirementId);
};


/**
 * Retrieves the priority for a specific requirement by its ID.
 * @param {Array} requirements - The array of requirement objects.
 * @param {number} requirementId - The ID of the requirement to find.
 * @returns {number|null} The priority of the requirement or null if not found.
 */
export function getPriorityByRequirementId(requirements, requirementId) {
    const requirement = requirements.find(req => req.requirement_id === requirementId);
    return requirement ? requirement.priority : null;
}

export function getNameByRequirementId(requirements, requirementId) {
    // Find the requirement based on the requirement_id
    const requirement = requirements.find(req => req.requirement_id === Number(requirementId));
    return requirement ? requirement.requirement_name : null;
}

export function getAllSwitchStates(projectData) {
    const switchData = projectData.reduce((acc, item) => {
        acc[item.requirement_id] = item.priority === 1;
        return acc;
    }, {});

    return switchData;
}


export function getCountryIDbycode(countryData, CountryCode) {

    // Loop through each country in the array
    for (let country of countryData) {
        // Check if the country code matches the provided CountryCode
        if (country.countrycode === CountryCode) {
            // Return the corresponding country ID
            return country.id;
        }
    }
    // If no matching country is found, return null or undefined
    return null;
}

export function getCountryCodebyId(countryData, CountryID) {
    // Loop through each country in the array
    for (let country of countryData) {
        // Check if the country code matches the provided CountryCode
        if (country.id === CountryID) {
            // Return the corresponding country ID
            return country.code;
        }
    }
    // If no matching country is found, return null or undefined
    return null;
}

// Method to return an array of indicator_ids
export const getIndicatorIds = (dataArray) => {
    return dataArray.map(item => item.id);
};

export const getCategoryName = (categories) => {
    const categoryMap = {};
    categories.forEach(category => {
        categoryMap[category.id] = category.name;
    });
    return categoryMap;
};