import React, { useEffect } from 'react'
import { useState } from 'react'
import { loginContext } from './Context'


const LoginContexProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showOverviewPage, setShowOverviewPage] = useState(false);
    const [showLoginPage, setShowLoginPage] = useState(false);
    const [showDecisionStepsPage, setShowDecisionStepsPage] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [companyData, setCompanyData] = useState([]);

    // Check localStorage for token when the component mounts
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
            setShowLoginPage(false); // Skip login page if token exists
            setShowOverviewPage(true);
        }
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setShowOverviewPage(false);
        setShowLoginPage(false);
    };


    return (
        <loginContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            showOverviewPage,
            setShowOverviewPage,
            showLoginPage,
            setShowLoginPage,
            accessToken,
            setAccessToken,
            showDecisionStepsPage,
            setShowDecisionStepsPage,
            logout,
            setCompanyData,
            companyData
        }} >
            {children}
        </loginContext.Provider>
    )
}

export default LoginContexProvider