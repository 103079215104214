import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid2';
import { Checkbox, FormControlLabel, Button, Tooltip, Typography } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";
import { cardContext, projectContext } from '../../hooks/customHooks/Context';
import { ProjectService } from '../../utils/ApiDataHandler';
import CategoriesComponent from './CategoriesComponent';
import CustomLoadingSpinner from '../../customComponents/CustomLoadingSpinner';
import { CustomCard } from '../../customComponents/CustomCard';
import { getSectorSpecificRequirements, getAllSelectedRequirementIds, isRequirementPresent, getPriorityByRequirementId, getAllSwitchStates } from '../../utils/ProjectDataHandler';
import CustomMinMaxSwitch from '../../customComponents/CustomMinMaxSwitch';
import SentenceCase from '../../customComponents/SentenceCase';
import './RequirementsPage.css';

const RequirementsPage = (props) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [requirements, setRequirements] = useState([]);
    const [isRequirementsLoading, setIsRequirementLoading] = useState(false);
    const { selectedProjectData, setSelectedProjectData, updateProjectData } = useContext(projectContext);
    const { setHeading, setCardWidth, setTextAlign, setDescription } = useContext(cardContext);
    const [selectedRequirementIds, setSelectedRequirementIds] = useState(getAllSelectedRequirementIds(selectedProjectData));
    const [switchStates, setSwitchStates] = useState(getAllSwitchStates(selectedProjectData.requirements));
    useEffect(() => {
        setHeading('What is important to your decision? ');
        setDescription('Consider which requirements your desired target market must fulfil in order for your scaling to be a success. Select your most important requirements from the following lists. Start with the industry-specific requirements and then check the general requirements for environment, economy & finance, social & demographics, politics, technology, law & legislation. Once you have decided which market requirements are relevant for your product, indicate whether the respective requirement should be minimized or maximized.');
        setCardWidth("100%");
        setTextAlign("left");
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);


    useEffect(() => {
        if (selectedCategory !== null) {
            const fetchCategoryData = async () => {
                try {
                    setIsRequirementLoading(true);
                    setRequirements([]);
                    const data = await ProjectService.fetchCategoriesById(selectedCategory);
                    const filteredRequirements = getSectorSpecificRequirements(data.requirements, selectedCategory, selectedProjectData.sub_sector_id);
                    const sortedRequirements = filteredRequirements.sort((a, b) => a.name.localeCompare(b.name));
                    setSelectedRequirementIds(getAllSelectedRequirementIds(selectedProjectData));
                    setRequirements(sortedRequirements);
                } catch (error) {
                    console.error('Failed to fetch category data:', error);
                } finally {
                    setIsRequirementLoading(false);
                }
            };

            fetchCategoryData();
        }
    }, [selectedCategory, selectedProjectData]);


    const updateRequirementStates = async () => {
        if (selectedRequirementIds.length > 0) {
            const selectedRequirementsData = selectedRequirementIds.map(requirementId => {
                const requirement = requirements.find(req => req.id === requirementId);
                return requirement ? {
                    category_id: selectedCategory,
                    requirement_id: requirement.id,
                    priority: switchStates[requirement.id] ? 1 : 0,
                    weighting: 1
                } : null;
            }).filter(item => item !== null);


            try {
                await ProjectService.updateProjectRequirements({
                    project_id: selectedProjectData.id,
                    current_step: selectedProjectData.current_step,
                    category_id: selectedCategory,
                    requirements: selectedRequirementsData.length === 0 ? [] : selectedRequirementsData
                });

                const updatedProjects = await ProjectService.fetchProjectById(selectedProjectData.id);
                // updateProjectData(updatedProjects);
                setSelectedProjectData(updatedProjects);
                setSelectedRequirementIds([]);
            } catch (error) {
                console.error('Failed to update requirement states:', error);
            }
        }
    };

    const onClickRequirementCheckBox = (requirementId) => {
        setSelectedRequirementIds(prevSelected =>
            prevSelected.includes(requirementId)
                ? prevSelected.filter(id => id !== requirementId)
                : [...prevSelected, requirementId]
        );
        setSwitchStates(prevStates => ({
            ...prevStates,
            [requirementId]: true
        }));
    };

    const onClickSwitchHandler = (requirementId, checked) => {
        setSwitchStates(prevStates => ({
            ...prevStates,
            [requirementId]: checked
        }));
    };

    const onClickNext = async () => {
        setIsRequirementLoading(true);
        await updateRequirementStates();
        if (selectedCategory < 7) {
            setSelectedCategory(prev => prev + 1);
            setIsRequirementLoading(false);
        } else {
            props.handleNext();
            setIsRequirementLoading(false);
        }

    };

    const onClickBack = async () => {
        setIsRequirementLoading(true);
        await updateRequirementStates();
        if (selectedCategory > 1) {
            setSelectedCategory(prev => prev - 1);
            setIsRequirementLoading(false);
        }
        else {
            props.handleBack();
            setIsRequirementLoading(false);
        }
    };
    return (
        <Box className="loader-container" >
            <Grid container spacing={1} className="Grid-container">
                <Grid size={{ xs: 4, md: 4, lg: 3 }}  >
                    <CategoriesComponent selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} updateRequirementStates={updateRequirementStates} setIsRequirementLoading={setIsRequirementLoading} />
                </Grid>
                <Grid size={{ xs: 8, md: 8, lg: 9 }} >
                    {isRequirementsLoading ? (
                        <CustomLoadingSpinner />
                    ) : requirements.length > 0 ? (
                        <Box className="requirement-container"  >
                            {requirements.map((requirement) => (
                                <Grid key={requirement.id} container  >
                                    <Grid size={8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}  >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        color: "#16697a",
                                                        "&.Mui-checked": {
                                                            color: "#16697a",
                                                        },
                                                    }}
                                                    onChange={() => onClickRequirementCheckBox(requirement.id)}
                                                    checked={isRequirementPresent(selectedRequirementIds, requirement.id)}
                                                />
                                            }
                                            label={
                                                <Typography variant="body1" align="left" sx={{ marginLeft: '8px' }}>
                                                    <SentenceCase>{requirement.name}</SentenceCase>
                                                </Typography>
                                            }
                                        />
                                        <Fragment>
                                            {requirement.shortInfo && (
                                                <Tooltip
                                                    title={
                                                        <div
                                                            style={{
                                                                backgroundColor: '#ffffff',
                                                                color: '#000000',
                                                                maxHeight: '40vh',
                                                                overflowY: 'auto',
                                                                whiteSpace: 'pre-wrap',
                                                                fontSize: '14px',
                                                                border: 'none',
                                                                padding: '5px',
                                                                scrollbarWidth: 'thin'
                                                            }}
                                                        >
                                                            {requirement.shortInfo}
                                                        </div>
                                                    }
                                                    PopperProps={{
                                                        sx: {
                                                            '.MuiTooltip-tooltip': {
                                                                border: 'none',
                                                                backgroundColor: 'transparent',
                                                                maxHeight: '40vh',
                                                                minWidth: '500px'
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <InfoIcon sx={{ color: '#1c5a73', width: '15px' }} />
                                                </Tooltip>
                                            )}

                                        </Fragment>
                                    </Grid>
                                    <Grid size={4} sx={{ display: 'flex', justifyContent: 'flex-start' }} >
                                        <CustomMinMaxSwitch
                                            isDisabled={!isRequirementPresent(selectedRequirementIds, requirement.id)}
                                            onChange={onClickSwitchHandler}
                                            requirementId={requirement.id}
                                            isChecked={isRequirementPresent(selectedRequirementIds, requirement.id) ? !!switchStates[requirement.id] : false}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                    ) : (
                        <div>No requirements to select</div>
                    )}

                </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', columnGap: '20px' }}>
                <Button onClick={onClickBack} disabled={isRequirementsLoading} sx={{
                    width: "100px",
                    bgcolor: '#ffffff',
                    color: '#16697A',
                    textTransform: 'none',
                    borderRadius: '5px',
                    '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                }}>Back</Button>
                <Button onClick={onClickNext} disabled={isRequirementsLoading} sx={{
                    width: "100px",
                    bgcolor: '#16697A',
                    color: '#ffffff',
                    textTransform: 'none',
                    borderRadius: '5px ',
                    '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                }}>Next</Button>
            </div>
        </Box>
    );
};

export default CustomCard(RequirementsPage);
