import React, { useState } from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';

const TermsAndConditions = () => {
    const [language, setLanguage] = useState('en');

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    return (
        <div
            style={{
                padding: '20px',
                position: 'relative',
                textAlign: 'left',
            }}
        >
            <FormControl
                sx={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    width: 150,
                }}
            >
                <Select
                    value={language}
                    onChange={handleLanguageChange}
                    sx={{
                        backgroundColor: 'white',
                        height: '40px',
                        border: '0px',
                    }}
                >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="de">Deutsch</MenuItem>
                </Select>
            </FormControl>

            {/* Heading */}
            <h1
                style={{
                    color: '#16697A',
                    fontSize: '34px',
                    fontWeight: 'bold',
                }}
            >
                {language === 'en' ? 'General Terms and Conditions' : 'Allgemeine Geschäftsbedingungen'}
            </h1>

            {/* Section 1 */}
            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',

                }}
            >
                1. {language === 'en' ? 'General' : 'Allgemeines'}
            </h2>
            <p>
                1.1 {language === 'en'
                    ? 'These General Terms and Conditions (GTC) apply to agreements concluded between the service provider and the client, incorporating these GTC.'
                    : 'Diese Allgemeinen Geschäftsbedingungen (AGB) für die Erbringung von digitalen Dienstleistungen gelten für Verträge, die zwischen dem Auftraggeber und dem Auftragnehmer unter Einbeziehung dieser AGB geschlossen werden. '}
            </p>
            <p>
                1.2 {language === 'en'
                    ? 'The Contractor is authorised to subcontract the necessary services in its own name and for its own account to subcontractors, who in turn may also use subcontractors. The Contractor shall remain the sole contractual partner of the Client. Subcontractors shall not be used if it is apparent to the Contractor that their use is contrary to the legitimate interests of the Client.'
                    : 'Der Auftragnehmer ist berechtigt, in eigenem Namen und auf eigene Rechnung die erforderlichen Leistungen an Subunternehmer zu vergeben, die ihrerseits ebenfalls Subunternehmer einsetzen dürfen. Der Auftragnehmer bleibt hierbei alleiniger Vertragspartner des Auftraggebers. Der Einsatz von Subunternehmern erfolgt nicht, wenn für den Auftragnehmer ersichtlich ist, dass deren Einsatz berechtigten Interessen des Auftraggebers zuwiderläuft. '}
            </p>
            <p>
                1.3  {language === 'en'
                    ? 'Insofar as other contractual documents or other terms and conditions in text or written form have become part of the contract in addition to these GTC, the provisions of these other contractual documents shall take precedence over these GTC in the event of a conflict.'
                    : 'Soweit neben diesen AGB weitere Vertragsdokumente oder andere Geschäftsbedingungen in Text- oder Schriftform Vertragsbestandteil geworden sind, gehen die Regelungen dieser weiteren Vertragsdokumente im Widerspruchsfalle den vorliegenden AGB vor.'}
            </p>
            <p>
                1.4  {language === 'en'
                    ? 'Subject to express consent, the Contractor shall not recognise any GTC that deviate from these GTC and are used by the Client.'
                    : 'Von diesen Geschäftsbedingungen abweichende AGB, die durch den Auftraggeber verwendet werden, erkennt Auftragnehmer – vorbehaltlich einer ausdrücklichen Zustimmung – nicht an. '}
            </p>

            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                2. {language === 'en' ? 'Subject matter of the contract and scope of services ' : 'Vertragsgegenstand und Leistungsumfang'}
            </h2>
            <p>
                {language === 'en'
                    ? 'The Contractor shall provide the following services to the Client as an independent contractor:'
                    : 'Der Auftragnehmer erbringt als selbständiger Unternehmer folgende Leistungen gegenüber dem Auftraggeber: '}
            </p>
            <p>
                {language === 'en'
                    ? 'xScaler UG (haftungsbeschränkt) operates tech-scaler.org, a digital decision support platform for systematic market analysis. The platform enables technology owners to identify the most suitable target markets for their innovation through a structured selection process of six steps.'
                    : 'Die xScaler UG (haftungsbeschränkt) betreibt mit tech-scaler.org eine digitale Entscheidungsunterstützungsplattform zur systematischen Marktanalyse. Die Plattform ermöglicht es Technologieinhabern, durch einen strukturierten Auswahlprozess, in sechs Schritten, die für ihre Innovation am besten geeigneten Zielmärkte zu identifizieren. '}
            </p>
            <p>
                {language === 'en'
                    ? 'The clients go through a multi-stage analysis process in which they select and prioritise specific market characteristics according to their individual requirements. Based on these client-defined criteria and their weighting, the platform creates a data-based ranking list of potential target markets that is optimised for the respective technology and the \client\'s business requirements. '
                    : 'Dabei durchlaufen die Auftraggeber einen mehrstufigen Analyseprozess, in dem sie spezifische Markteigenschaften entsprechend ihrer individuellen Anforderungen auswählen und priorisieren. Auf Basis dieser Auftraggeber definierten Kriterien und deren Gewichtung erstellt die Plattform eine datenbasierte Rangliste potenzieller Zielmärkte, die optimal auf die jeweilige Technologie und die geschäftlichen Anforderungen des Auftraggebers abgestimmt ist. '}
            </p>
            <p>
                2.2 {language === 'en'
                    ? 'Upon payment of the usage fee, the client receives access to the platform for 30 days. The utilisation includes: '
                    : 'Mit Zahlung der Nutzungsgebühr erhält der Auftraggeber für 30 Tage Zugriff auf die Plattform. Die Nutzung umfasst: '}
            </p>
            <ul>
                <li> {language === 'en' ? 'Carrying out the complete analysis process' : 'Durchführung des vollständigen Analyseprozesses'}</li>
                <li> {language === 'en' ? 'Online access to the analysis results' : 'Online-Einsicht in die Analyseergebnisse'}</li>
                <li> {language === 'en' ? 'Export of the results as a PDF document' : 'Export der Ergebnisse als PDF-Dokument '}</li>
                <li> {language === 'en' ? 'Access to own historical analyses ' : 'Zugriff auf eigene historische Analysen '}</li>
                <li> {language === 'en' ? 'E-mail support with a response time of 24 hours ' : 'E-Mail-Support mit einer Reaktionszeit von 24 Stunden'}</li>
            </ul>
            <p>
                2.3 {language === 'en'
                    ? 'The Contractor shall provide the contractual services with the greatest possible care and diligence in accordance with the latest standards, rules and knowledge. '
                    : 'Der Auftragnehmer erbringt die vertragsgemäßen Leistungen mit größtmöglicher Sorgfalt und Gewissenhaftigkeit nach dem jeweils neuesten Stand, neuesten Regeln und Erkenntnissen. '}
            </p>
            <p>
                2.4 {language === 'en'
                    ? 'Provision of non-automated services: The Contractor undertakes to provide the agreed consultancy services in accordance with the contract. When carrying out its activities, it shall be free from instructions with regard to the type, place and time of service provision. The Contractor shall determine the division of its activities independently in order to ensure optimum efficiency in the realisation of the subject matter of the contract. The services shall be provided in consultation and coordination with the client. '
                    : 'Erbringung nicht-automatisierter Dienstleistungen: Der Auftragnehmer verpflichtet sich zur vertragsgemäßen Erbringung der vereinbarten Beratungsleistungen. Bei der Durchführung seiner Tätigkeit ist er in Bezug auf Art, Ort und Zeit der Leistungserbringung weisungsfrei. Der Auftragnehmer legt die Einteilung seiner Tätigkeiten eigenständig fest, um eine optimale Effizienz bei der Realisierung des Vertragsgegenstandes zu gewährleisten. Die Leistungserbringung erfolgt in Abstimmung und Koordination mit dem Auftraggeber.'}
            </p>

            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                3. {language === 'en' ? 'Rights of use and licence conditions  ' : 'Nutzungsrechte und Lizenzbedingungen '}
            </h2>
            <p>
                3.1 {language === 'en'
                    ? 'Scope of licence: '
                    : 'Lizenzumfang'}
            </p>
            <ul
            >
                <li> {language === 'en' ? 'The licence of use is personal and non-transferable. ' : 'Die Nutzungslizenz ist persönlich und nicht übertragbar.'}</li>
                <li> {language === 'en' ? 'The licence is bound to the customer account under which the purchase contract was concluded.' : 'Die Lizenz ist an den Auftraggeber Account gebunden, unter dem der Kaufvertrag abgeschlossen wurde.'}</li>
                <li> {language === 'en' ? 'The use of an account by several persons, even within the same company, is not permitted. ' : 'Die Nutzung eines Accounts durch mehrere Personen, auch innerhalb desselben Unternehmens, ist nicht gestattet. '}</li>
            </ul>
            <p>
                3.2 {language === 'en'
                    ? 'Use of the analysis results: '
                    : 'Verwendung der Analyseergebnisse: '}
            </p>
            <ul
            >
                <li> {language === 'en' ? 'The analysis results may be shared and used within the \client\'s company for internal purposes without restriction. ' : 'Die Analyseergebnisse dürfen innerhalb des Unternehmens des Auftraggebers für interne Zwecke uneingeschränkt geteilt und verwendet werden. '}</li>
                <li> {language === 'en' ? 'Whenever the analysis results are published, it must be stated that they were produced by xScaler via tech-scaler.org.' : 'Bei jeglicher Veröffentlichung der Analyseergebnisse ist zwingend anzugeben, dass diese von xScaler über tech-scaler.org erstellt wurden. '}</li>
                <li> {language === 'en' ? 'The resale or transfer of the analysis results to third parties is prohibited. ' : 'Der Weiterverkauf oder die Weitergabe der Analyseergebnisse an Dritte ist untersagt.'}</li>
            </ul>
            <p>
                3.3 {language === 'en'
                    ? 'Restrictions on use: '
                    : 'Nutzungsbeschränkungen:'}
            </p>
            <ul >
                <li> {language === 'en' ? 'The number of analyses that can be carried out is limited to the quantity agreed in the purchase contract. ' : 'Die Anzahl der durchführbaren Analysen ist auf die im Kaufvertrag vereinbarte Menge beschränkt. '}</li>
                <li> {language === 'en' ? 'There are no geographical restrictions on the use of the platform. ' : 'Es bestehen keine geografischen Einschränkungen für die Nutzung der Plattform. '}</li>
                <li> {language === 'en' ? 'The granting of sub-licences is not permitted. ' : 'Die Vergabe von Unterlizenzen ist nicht gestattet. '}</li>
            </ul>
            <p>
                3.4 {language === 'en'
                    ? 'Violation of terms of use: '
                    : 'Verstoß gegen Nutzungsbedingungen: '}
            </p>
            <ul >
                <li> {language === 'en' ? 'In the event of violations of these terms of use, the contractor reserves the right to block access to the platform without prior notice. ' : 'Bei Verstößen gegen diese Nutzungsbedingungen behält sich der Auftragnehmer das Recht vor, den Zugang zur Plattform ohne vorherige Ankündigung zu sperren. '}</li>
                <li> {language === 'en' ? 'We reserve the right to take further legal action in the event of misuse. ' : 'Weitere rechtliche Schritte bei Missbrauch bleiben vorbehalten. '}</li>
            </ul>
            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                4. {language === 'en' ? 'right of cancellation for digital services ' : 'Widerrufsrecht bei digitalen Dienstleistungen '}
            </h2>
            <p>
                {language === 'en'
                    ? 'The client expressly agrees that the performance of the contractually agreed service shall commence immediately after conclusion of the contract. The client confirms that he thereby loses his statutory right of cancellation as soon as the service has been provided in full (Section 356 (5) BGB). The service is deemed to have been provided in full when the client has the ranking of the ten best markets displayed. This provision applies both to automated platform services and to supplementary advisory services in accordance with section 2.4. '
                    : 'Der Auftraggeber erklärt sich ausdrücklich damit einverstanden, dass die Ausführung der vertraglich vereinbarten Dienstleistung unmittelbar nach Vertragsschluss beginnt. Der Auftraggeber bestätigt, dass er hierdurch sein gesetzliches Widerrufsrecht verliert, sobald die Dienstleistung vollständig erbracht wurde (§ 356 Abs. 5 BGB). Die Dienstleistung gilt als vollständig erbracht, wenn sich der Auftraggeber, das Ranking der zehn besten Märkte anzeigen lässt. Diese Regelung gilt sowohl für die automatisierten Plattformleistungen als auch für ergänzende Beratungsleistungen gemäß Abschnitt 2.4. '}
            </p>
            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                5. {language === 'en' ? 'Obligations of the client to co-operate ' : 'Mitwirkungspflichten des Auftraggebers '}
            </h2>
            <p>
                {language === 'en'
                    ? 'It is the responsibility of the Client to provide complete and correct information, data and other content to be provided by it for the purpose of service fulfilment. The Contractor shall not be liable to the Client in any way for delays and delays in the provision of services caused by late and necessary co-operation or input by the Client. '
                    : 'Es obliegt dem Auftraggeber, die von ihm zum Zwecke der Leistungserfüllung zur Verfügung zu stellenden Informationen, Daten und sonstigen Inhalte vollständig und korrekt mitzuteilen. Für Verzögerungen und Verspätungen bei der Leistungserbringung, die durch eine verspätete und notwendige Mit- bzw. Zuarbeit des Kunden entstehen, ist der Auftragnehmer gegenüber dem Kunden in keinerlei Hinsicht verantwortlich.  '}
            </p>
            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                6. {language === 'en' ? 'Data protection and data security  ' : 'Datenschutz und Datensicherheit '}
            </h2>
            <p>
                6.1 {language === 'en'
                    ? 'The Contractor shall treat all information provided by the Client - whether digitally via the platform or in counselling sessions - as strictly confidential. The information provided shall be used exclusively within the scope of the scope of services defined under 2.1 and its optimisation. This confidentiality obligation is imposed on all employees and third parties who have access to this information. The confidentiality obligation shall apply indefinitely and shall continue to apply after termination of the contractual relationship. '
                    : 'Der Auftragnehmer behandelt sämtliche vom Auftraggeber bereitgestellten Informationen – ob digital über die Plattform oder in Beratungsgesprächen – streng vertraulich. Die bereitgestellten Informationen werden ausschließlich im Rahmen des unter 2.1 definierten Leistungsumfangs und dessen Optimierung verwendet. Diese Vertraulichkeitspflicht wird allen Mitarbeitern sowie Dritten auferlegt, die Zugang zu diesen Informationen erhalten. Die Geheimhaltungsverpflichtung gilt zeitlich unbegrenzt und besteht auch nach Beendigung des Vertragsverhältnisses fort. '}
            </p>
            <p>
                6.2 {language === 'en'
                    ? 'Data storage: '
                    : 'Datenspeicherung:'}
            </p>
            <ul
            >
                <li> {language === 'en' ? 'Client input and analysis results are stored for 2 years in anonymised form. ' : 'Auftraggebereingaben und Analyseergebnisse werden für 2 Jahre in anonymisierter Form gespeichert. '}</li>
                <li> {language === 'en' ? 'The data is used to optimise the platform and improve user-friendliness.' : 'Die Daten werden zur Optimierung der Plattform und Verbesserung der Nutzerfreundlichkeit verwendet. '}</li>
                <li> {language === 'en' ? 'Individual analysis results are not published. ' : 'Eine Veröffentlichung individueller Analyseergebnisse erfolgt nicht. '}</li>
                <li> {language === 'en' ? 'The data collected during the term of the contract is stored in accordance with the statutory provisions and the privacy policy.' : 'Die während der Vertragslaufzeit erhobenen Daten werden gemäß den gesetzlichen Bestimmungen und der Datenschutzerklärung gespeichert.'}</li>
                <li> {language === 'en' ? 'The collection, processing and use of personal data is carried out in compliance with the applicable data protection regulations. ' : 'Die Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten erfolgt unter Beachtung der geltenden datenschutzrechtlichen Bestimmungen. '}</li>
                <li> {language === 'en' ? 'The details of the processing of personal data are regulated in the privacy policy. ' : 'Die Einzelheiten zur Verarbeitung personenbezogener Daten sind in der Datenschutzerklärung geregelt. '}</li>
                <li> {language === 'en' ? 'The Privacy Policy is an integral part of these General Terms and Conditions. ' : 'Die Datenschutzerklärung ist Bestandteil dieser Allgemeinen Geschäftsbedingungen.'}</li>
            </ul>
            <p>
                6.3 {language === 'en'
                    ? 'Data security: '
                    : 'Datensicherheit'}
            </p>
            <ul
            >
                <li> {language === 'en' ? 'The Contractor shall implement technical and organisational security measures to protect the \Client\'s data against accidental or intentional manipulation, loss, destruction or access by unauthorised persons. ' : 'Der Auftragnehmer setzt technische und organisatorische Sicherheitsmaßnahmen ein, um die Daten der Auftraggeber gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder Zugriff durch unberechtigte Personen zu schützen. '}</li>
                <li> {language === 'en' ? 'The security measures are continuously adapted in line with technological developments. ' : 'Die Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend angepasst. '}</li>
                <li> {language === 'en' ? 'The client is responsible for the secure storage of his access data. ' : 'Der Auftraggeber ist für die sichere Verwahrung seiner Zugangsdaten verantwortlich. '}</li>
                <li> {language === 'en' ? 'The client undertakes to keep his access data secret and to protect it from access by unauthorised third parties. ' : 'Der Auftraggeber verpflichtet sich, seine Zugangsdaten geheim zu halten und vor dem Zugriff durch unbefugte Dritte zu schützen. '}</li>
                <li> {language === 'en' ? 'The Contractor must be informed immediately in the event of suspected misuse of the access data. ' : 'Bei Verdacht auf Missbrauch der Zugangsdaten ist der Auftragnehmer unverzüglich zu informieren. '}</li>
                <li> {language === 'en' ? 'In the event of data protection breaches, the client shall be informed immediately and all necessary measures shall be taken to limit the damage. ' : 'Bei Datenschutzverletzungen werden Auftraggeber unverzüglich informiert und alle erforderlichen Maßnahmen zur Schadensbegrenzung ergriffen. '}</li>
            </ul>
            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                7. {language === 'en' ? 'Terms of payment, contract term and cancellation ' : 'Zahlungsbedingungen, Vertragslaufzeit und Kündigung '}
            </h2>
            <p>
                7.1 {language === 'en'
                    ? 'Contract term: '
                    : 'Vertragslaufzeit:'}
            </p>
            <ul>
                <li> {language === 'en' ? 'The term of use is 30 days from the first access to the platform.' : 'Die Nutzungsdauer beträgt 30 Tage ab dem ersten Zugang zur Plattform. '}</li>
                <li> {language === 'en' ? 'The contract ends automatically at the end of the 30 days. ' : 'Der Vertrag endet automatisch nach Ablauf der 30 Tage. '}</li>
                <li> {language === 'en' ? 'There is no automatic renewal. ' : 'Eine automatische Verlängerung erfolgt nicht. '}</li>
                <li> {language === 'en' ? 'The client will be informed of the imminent end by e-mail before the end of the period of use and will be given the option of manual renewal. ' : 'Der Auftraggeber wird vor Ablauf der Nutzungsdauer per E-Mail über das bevorstehende Ende informiert und erhält die Möglichkeit zur manuellen Verlängerung. '}</li>
            </ul>
            <p>
                7.2 {language === 'en'
                    ? 'Prices and payment modalities: '
                    : 'Preise und Zahlungsmodalitäten: '}
            </p>
            <ul>
                <li> {language === 'en' ? 'The amount of the usage fee for the 30-day access shall be agreed individually between the Contractor and the Client. ' : 'Die Höhe der Nutzungsgebühr für den 30-tägigen Zugang wird individuell zwischen dem Auftragnehmer und dem Auftraggeber vereinbart. '}</li>
                <li> {language === 'en' ? 'All prices are subject to statutory value added tax.' : ' Alle Preise verstehen sich zuzüglich der gesetzlichen Mehrwertsteuer. '}</li>
                <li> {language === 'en' ? 'Payment shall be made via the payment service provider Stripe or on account. ' : 'Die Zahlung erfolgt über den Zahlungsdienstleister Stripe oder auf Rechnung.'}</li>
                <li> {language === 'en' ? 'In the case of payment via Stripe, payment is due when platform access is granted. ' : 'Bei Zahlung über Stripe wird die Zahlung mit Gewährung des Plattformzugangs fällig. '}</li>
                <li> {language === 'en' ? 'If payment is made on account, the invoice amount must be paid without deduction within 14 days of invoicing. ' : 'Bei Zahlung auf Rechnung ist der Rechnungsbetrag innerhalb von 14 Tagen nach Rechnungsstellung ohne Abzug zu zahlen. '}</li>
            </ul>
            <p>
                7.3 {language === 'en'
                    ? 'Invoicing:'
                    : 'Rechnungsstellung:'}
            </p>
            <ul>
                <li> {language === 'en' ? 'The client shall receive an invoice in accordance with the tax regulations. ' : 'Der Auftraggeber erhält eine den steuerrechtlichen Vorgaben entsprechende Rechnung. '}</li>
                <li> {language === 'en' ? 'The invoice shall be sent electronically. ' : 'Die Rechnung wird elektronisch übermittelt. '}</li>
                <li> {language === 'en' ? 'The invoice shall contain all information required by law, including VAT. ' : 'Die Rechnung enthält alle gesetzlich vorgeschriebenen Angaben einschließlich der Mehrwertsteuer.'}</li>
            </ul>
            <p>
                7.4 {language === 'en'
                    ? 'Early termination and cancellation: '
                    : 'Vorzeitige Beendigung und Widerruf: '}
            </p>
            <ul>
                <li> {language === 'en' ? 'Premature termination by the client is possible at any time. ' : 'Eine vorzeitige Beendigung durch den Auftraggeber ist jederzeit möglich. '}</li>
                <li> {language === 'en' ? 'In the event of premature termination, there is no entitlement to reimbursement of the usage fee. ' : 'Bei vorzeitiger Beendigung besteht kein Anspruch auf Rückerstattung der Nutzungsgebühr. '}</li>
                <li> {language === 'en' ? 'The client has a statutory right of cancellation. ' : 'Dem Auftraggeber steht grundsätzlich ein gesetzliches Widerrufsrecht zu. '}</li>
                <li> {language === 'en' ? 'The right of cancellation expires prematurely if the client expressly waives his right of cancellation by activating the corresponding checkbox before the analysis results are displayed. ' : 'Das Widerrufsrecht erlischt vorzeitig, wenn der Auftraggeber vor Anzeige der Analyseergebnisse ausdrücklich durch Aktivierung der entsprechenden Checkbox auf sein Widerrufsrecht verzichtet. '}</li>
                <li> {language === 'en' ? 'The complete cancellation policy is part of the contract. ' : 'Die vollständige Widerrufsbelehrung ist Bestandteil des Vertrags. '}</li>
            </ul>
            <p>
                7.5 {language === 'en'
                    ? 'Consequences of contract termination: '
                    : 'Folgen der Vertragsbeendigung: '}
            </p>
            <ul>
                <li> {language === 'en' ? 'Upon expiry of the contract term or in the event of premature termination, access to the platform shall be blocked. ' : 'Mit Ablauf der Vertragslaufzeit oder bei vorzeitiger Beendigung wird der Zugang zur Plattform gesperrt. '}</li>
                <li> {language === 'en' ? 'After the end of the contract, the client no longer has access to the platform and the data contained therein. ' : 'Nach Vertragsende hat der Auftraggeber keinen Zugriff mehr auf die Plattform und die darin enthaltenen Daten. '}</li>
                <li> {language === 'en' ? 'It is recommended that all relevant data and analysis results be exported before the end of the contract. ' : 'Es wird empfohlen, vor Vertragsende alle relevanten Daten und Analyseergebnisse zu exportieren. '}</li>
                <li> {language === 'en' ? 'The Contractor must return or destroy all documents and other content provided to it immediately after termination of the contract, at the \Client\'s discretion. The assertion of a right of retention is excluded. Electronic data must be completely deleted. Exceptions to this are documents and data for which there is a longer statutory retention obligation, but only until the end of the respective retention period. The Contractor shall confirm the deletion in writing to the Company at the \latter\'s request.  ' : 'Der Auftragnehmer hat alle ihm überlassenen Unterlagen und sonstigen Inhalte nach Vertragsbeendigung unverzüglich nach Wahl des Kunden zurückzugeben oder zu vernichten. Die Geltendmachung eines Zurückbehaltungsrechts daran ist ausgeschlossen. Elektronische Daten sind vollständig zu löschen. Ausgenommen davon sind Unterlagen und Daten, hinsichtlich derer eine längere gesetzliche Aufbewahrungspflicht besteht, jedoch nur bis zum Ende der jeweiligen Aufbewahrungsfrist. Der Auftragnehmer hat dem Unternehmen auf dessen Verlangen die Löschung schriftlich zu bestätigen.'}</li>
            </ul>
            <p>
                7.6 {language === 'en'
                    ? 'Price changes: '
                    : 'Preisänderungen:'}
            </p>
            <ul>
                <li> {language === 'en' ? 'The client shall be notified of changes to the agreed prices at least 14 days before they come into effect.' : 'Änderungen der vereinbarten Preise werden dem Auftraggeber mindestens 14 Tage vor Inkrafttreten mitgeteilt.'}</li>
                <li> {language === 'en' ? 'The price changes shall only apply to newly concluded contracts. ' : 'Die Preisänderungen gelten nur für neu abgeschlossene Verträge. '}</li>
                <li> {language === 'en' ? 'Periods of utilisation already paid for shall remain unaffected by price changes. ' : 'Bereits bezahlte Nutzungszeiträume bleiben von Preisänderungen unberührt. '}</li>
            </ul>
            <p>
                7.7 {language === 'en'
                    ? 'Default of payment:'
                    : 'Zahlungsverzug:'}
            </p>
            <ul>
                <li> {language === 'en' ? 'In the event of late payment, the Contractor shall be entitled to block access to the Platform without prior notice. ' : 'Bei Zahlungsverzug ist der Auftragnehmer berechtigt, den Zugang zur Plattform ohne vorherige Ankündigung zu sperren.'}</li>
                <li> {language === 'en' ? 'Access shall be blocked until receipt of the outstanding payment. ' : 'Die Sperrung erfolgt bis zum Eingang der ausstehenden Zahlung. '}</li>
                <li> {language === 'en' ? 'The obligation to pay the agreed remuneration shall remain unaffected by the blocking.' : 'Die Verpflichtung zur Zahlung der vereinbarten Vergütung bleibt von der Sperrung unberührt. '}</li>
                <li> {language === 'en' ? 'We reserve the right to assert further claims for late payment. ' : 'Weitere Ansprüche wegen Zahlungsverzugs bleiben vorbehalten. '}</li>
            </ul>
            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                8. {language === 'en' ? 'Availability and maintenance ' : 'Verfügbarkeit und Wartung '}
            </h2>
            <p>
                8.1 {language === 'en'
                    ? 'System availability:'
                    : 'Systemverfügbarkeit:'}
            </p>
            <ul>
                <li> {language === 'en' ? 'The platform is generally available 24 hours a day, 7 days a week. ' : 'Die Plattform steht grundsätzlich 24 Stunden täglich, 7 Tage die Woche zur Verfügung. '}</li>
                <li> {language === 'en' ? 'The guaranteed availability is 95% on a monthly average. ' : 'Die garantierte Verfügbarkeit beträgt 95% im Monatsmittel. '}</li>
                <li> {language === 'en' ? 'Downtimes due to force majeure, planned maintenance work or internet faults are not taken into account when calculating availability. ' : 'Ausfallzeiten durch höhere Gewalt, geplante Wartungsarbeiten oder Störungen im Internet werden nicht zur Berechnung der Verfügbarkeit herangezogen. '}</li>
            </ul>
            <p>
                8.2 {language === 'en'
                    ? 'Maintenance work: '
                    : 'Wartungsarbeiten: '}
            </p>
            <ul>
                <li> {language === 'en' ? 'Scheduled maintenance work is primarily carried out at night (22:00-06:00 CET) and at weekends. ' : 'Planmäßige Wartungsarbeiten werden vorrangig nachts (22:00-06:00 Uhr MEZ) und an Wochenenden durchgeführt. '}</li>
                <li> {language === 'en' ? 'Scheduled maintenance work will be announced by e-mail at least 48 hours in advance. ' : 'Geplante Wartungsarbeiten werden mindestens 48 Stunden im Voraus per E-Mail angekündigt. '}</li>
                <li> {language === 'en' ? 'The total duration of scheduled maintenance work shall not exceed 8 hours per month. ' : 'Die Gesamtdauer geplanter Wartungsarbeiten überschreitet nicht 8 Stunden pro Monat. '}</li>

            </ul>
            <p>
                8.3 {language === 'en'
                    ? 'Fault management:'
                    : 'Störungsmanagement:'}
            </p>
            <ul>
                <li> {language === 'en' ? 'In the event of unforeseen technical faults, functionality is guaranteed to be restored within 36 hours ' : 'Bei unvorhergesehenen technischen Störungen wird eine Wiederherstellung der Funktionalität innerhalb von 36 Stunden garantiert '}</li>
                <li> {language === 'en' ? 'Faults can be reported at any time by e-mail to support@tech-scaler.org ' : 'Störungen können jederzeit per E-Mail an support@tech-scaler.org gemeldet werden '}</li>
                <li> {language === 'en' ? 'Clients are informed of the status of fault rectification by e-mail ' : 'Die Auftraggeber werden über den Status der Störungsbehebung per E-Mail informiert '}</li>
                <li> {language === 'en' ? 'Critical faults are processed with the highest priority ' : 'Kritische Störungen werden mit höchster Priorität bearbeitet '}</li>
            </ul>
            <p>
                8.4 {language === 'en'
                    ? 'Technical requirements:'
                    : 'Technische Voraussetzungen:'}
            </p>
            <ul>
                <li> {language === 'en' ? 'The platform is optimised for the latest versions of the Google Chrome and Microsoft Edge browsers ' : 'Die Plattform ist optimiert für die Browser Google Chrome und Microsoft Edge in ihrer jeweils aktuellen Version '}</li>
                <li> {language === 'en' ? 'The use of other browsers is at your own risk and may lead to functional restrictions ' : 'Die Nutzung anderer Browser erfolgt auf eigenes Risiko und kann zu Funktionseinschränkungen führen '}</li>
                <li> {language === 'en' ? 'A stable internet connection with at least 10 Mbit/s is recommended ' : 'Eine stabile Internetverbindung mit mindestens 10 Mbit/s wird empfohlen'}</li>
                <li> {language === 'en' ? 'The client is responsible for fulfilling the technical requirements ' : 'Der Auftraggeber ist selbst für die Erfüllung der technischen Voraussetzungen verantwortlich '}</li>
            </ul>
            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                9. {language === 'en' ? 'Liability and warranty ' : 'Haftung und Gewährleistung'}
            </h2>
            <p>
                9.1 {language === 'en'
                    ? 'Guarantee of platform availability: '
                    : 'Gewährleistung der Plattformverfügbarkeit: '}
            </p>
            <ul>
                <li> {language === 'en' ? 'Downtimes due to force majeure, planned maintenance work or disruptions on the Internet are not included in the calculation of availability. ' : 'Ausfallzeiten durch höhere Gewalt, geplante Wartungsarbeiten oder Störungen im Internet werden nicht zur Berechnung der Verfügbarkeit herangezogen. '}</li>
                <li> {language === 'en' ? 'Defects must be reported immediately to support@tech-scaler.org. ' : 'Mängel sind unverzüglich an support@tech-scaler.org zu melden.'}</li>
                <li> {language === 'en' ? 'The contractor undertakes to analyse reported defects as quickly as possible and, if necessary, to rectify them. ' : 'Der Auftragnehmer verpflichtet sich, gemeldete Mängel schnellstmöglich zu analysieren und, soweit erforderlich, zu beheben. '}</li>
            </ul>
            <p>
                9.2 {language === 'en'
                    ? 'Data quality and availability: '
                    : 'Datenqualität und -verfügbarkeit: '}
            </p>
            <ul>
                <li> {language === 'en' ? 'Data from transparent, scientific and multilateral organisations are aggregated to identify relevant markets.' : 'Zur Identifikation relevanter Märkte werden Daten von transparenten, wissenschaftlich arbeitenden und multilateralen Organisationen aggregiert.'}</li>
                <li> {language === 'en' ? 'Data gaps for certain countries or markets are possible and do not constitute a deficiency. ' : 'Datenlücken für bestimmte Länder oder Märkte sind möglich und stellen keinen Mangel dar. '}</li>
                <li> {language === 'en' ? 'These data gaps serve as an indicator of data availability and prioritisation of data collection in the respective markets. ' : 'Diese Datenlücken dienen als Indikator für die Datenverfügbarkeit und Priorisierung der Datenerhebung in den jeweiligen Märkten. '}</li>
            </ul>
            <p>
                9.3 {language === 'en'
                    ? 'Limitations of liability: '
                    : 'Haftungsbeschränkungen: '}
            </p>
            <ul>
                <li> {language === 'en' ? 'The Contractor shall support the Client in identifying suitable markets for its technology, as defined under 2.1. The analysis provided is based on public and private data sources that are subjected to a scientific quality check. This data is subject to continuous change due to political, economic and climatic developments. The resulting recommendations reflect only the data available at the time of analysis.' : 'Der Auftragnehmer unterstützt den Auftraggeber bei der Identifikation geeigneter Märkte für dessen Technologie, wie unter 2.1 definiert. Die zur Verfügung gestellte Analyse basiert auf öffentlichen und privaten Datenquellen, die einer wissenschaftlichen Qualitätsprüfung unterzogen werden. Diese Daten unterliegen kontinuierlichen Veränderungen durch politische, wirtschaftliche und klimatische Entwicklungen. Die daraus resultierenden Empfehlungen spiegeln ausschließlich die zum Analysezeitpunkt verfügbare Datenlage wider. '}</li>
                <li> {language === 'en' ? 'The contractor accepts no liability for: ' : 'Der Auftragnehmer übernimmt keine Haftung für:'}</li>
                <ul>
                    <li> {language === 'en' ? 'Business decisions made by the client on the basis of the platform results or advisory recommendations ' : 'Geschäftsentscheidungen des Auftraggebers, die auf Basis der Plattformergebnisse oder Beratungsempfehlungen getroffen werden '}</li>
                    <li> {language === 'en' ? 'Economic losses or lost profits resulting from the use of the platform or advice ' : 'Wirtschaftliche Verluste oder entgangene Gewinne, die aus der Nutzung der Plattform oder der Beratung resultieren '}</li>
                    <li> {language === 'en' ? 'Damage due to incorrect or incomplete data input by the client ' : 'Schäden durch fehlerhafte oder unvollständige Dateneingaben des Auftraggebers '}</li>
                    <li> {language === 'en' ? 'Loss of timeliness of the analysis results due to changing market conditions ' : 'Aktualitätsverlust der Analyseergebnisse durch sich ändernde Marktbedingungen '}</li>
                </ul>
                <li> {language === 'en' ? 'The Client shall indemnify the Contractor against any third-party claims asserted against the Contractor due to breaches of these contractual terms and conditions or applicable law by the Client. ' : 'Der Auftraggeber stellt den Auftragnehmer von jeglichen Ansprüchen Dritter frei, die gegen den Auftragnehmer aufgrund von Verstößen des Kunden gegen diese Vertragsbedingungen oder gegen geltendes Recht geltend gemacht werden. '}</li>
            </ul>
            <p>
                9.4 {language === 'en'
                    ? 'Compensation for damages: '
                    : 'Schadensersatz: '}
            </p>
            <ul>
                <li> {language === 'en' ? 'A claim for damages is generally excluded.' : 'Ein Anspruch auf Schadensersatz ist grundsätzlich ausgeschlossen. '}</li>
                <li> {language === 'en' ? 'The maximum compensation is limited to the amount of the monthly usage fee. ' : 'Die maximale Ersatzleistung ist auf die Höhe der monatlichen Nutzungsgebühr begrenzt. '}</li>
                <li> {language === 'en' ? 'These limitations of liability do not apply to ' : 'Diese Haftungsbeschränkungen gelten nicht für:  '}</li>
                <ul>
                    <li> {language === 'en' ? 'intent and gross negligence ' : 'Vorsatz und grobe Fahrlässigkeit '}</li>
                    <li> {language === 'en' ? 'injury to life, limb or health ' : 'Verletzung von Leben, Körper oder Gesundheit '}</li>
                    <li> {language === 'en' ? 'breach of essential contractual obligations (cardinal obligations) ' : 'Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) '}</li>
                    <li> {language === 'en' ? 'claims under the Product Liability Act  ' : 'Ansprüche nach dem Produkthaftungsgesetz '}</li>
                </ul>
            </ul>
            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                10. {language === 'en' ? 'Protection of the platform and intellectual property ' : ' Schutz der Plattform und geistiges Eigentum '}
            </h2>
            <p>
                10.1 {language === 'en'
                    ? 'Copyright and intellectual property: '
                    : 'Urheberrecht und geistiges Eigentum:  '}
            </p>
            <ul >
                <li> {language === 'en' ? 'The tech-scaler.org platform and all its components (including software, design, logos, brands, databases and algorithms) are the intellectual property of the Contractor. ' : 'Die Plattform tech-scaler.org und alle ihre Bestandteile (einschließlich Software, Design, Logos, Marken, Datenbanken und Algorithmen) sind geistiges Eigentum des Auftragnehmers. '}</li>
                <li> {language === 'en' ? 'Any use outside the contractually agreed purposes is prohibited.  ' : 'Jegliche Nutzung außerhalb der vertraglich vereinbarten Zwecke ist untersagt. '}</li>
                <li> {language === 'en' ? 'Reverse engineering, decompiling or disassembling the platform is prohibited. ' : 'Das Reverse Engineering, Dekompilieren oder Disassemblieren der Plattform ist untersagt. '}</li>
            </ul>

            <p>
                10.2 {language === 'en'
                    ? 'Protection against misuse: '
                    : 'Schutz vor Missbrauch:  '}
            </p>
            <p>
                {language === 'en'
                    ? 'In particular, misuse is deemed to be improper use: '
                    : 'Als missbräuchliche Nutzung gilt insbesondere: '}
            </p>
            <ul >
                <li> {language === 'en' ? 'Any form of automated data extraction (scraping) or the use of crawlers, bots or similar tools ' : 'Jede Form der automatisierten Datenextraktion (Scraping) oder die Verwendung von Crawlern, Bots oder ähnlichen Tools '}</li>
                <li> {language === 'en' ? 'Systematic copying, saving or forwarding of platform content outside the intended scope of use ' : 'Das systematische Kopieren, Speichern oder Weitergeben von Plattforminhalten außerhalb des vorgesehenen Nutzungsumfangs '}</li>
                <li> {language === 'en' ? 'Excessive number of requests that may affect system performance ' : 'Übermäßige Anzahl von Anfragen, die die Systemleistung beeinträchtigen können '}</li>
                <li> {language === 'en' ? 'Attempts to circumvent security mechanisms or identify vulnerabilities ' : 'Versuche, Sicherheitsmechanismen zu umgehen oder Schwachstellen zu identifizieren '}</li>
                <li> {language === 'en' ? 'Passing on access data to third parties ' : 'Die Weitergabe von Zugangsdaten an Dritte '}</li>
                <li> {language === 'en' ? 'Using the platform via automated interfaces without explicit authorization ' : 'Die Nutzung der Plattform über automatisierte Schnittstellen ohne explizite Genehmigung '}</li>
            </ul>
            <p>
                {language === 'en'
                    ? 'The Contractor reserves the right to temporarily or permanently block access without prior notice in the event of suspected misuse. The client undertakes not to take any technical or other measures that could impair the functionality, performance or security of the platform. The use of VPNs or proxy servers to conceal identity is only permitted with prior written consent and stating the purpose of use. '
                    : 'Der Auftragnehmer behält sich vor, bei Verdacht auf missbräuchliche Nutzung den Zugang ohne vorherige Ankündigung temporär oder dauerhaft zu sperren. Der Auftraggeber verpflichtet sich, keine technischen oder sonstigen Maßnahmen zu ergreifen, die die Funktionalität, Leistung oder Sicherheit der Plattform beeinträchtigen könnten. Die Nutzung von VPN oder Proxy-Servern zur Verschleierung der Identität ist nur nach vorheriger schriftlicher Zustimmung und unter Angabe des Verwendungszwecks erlaubt. '}
            </p>
            <p>
                10.3 {language === 'en'
                    ? 'Documentation and monitoring: '
                    : 'Dokumentation und Monitoring:  '}
            </p>
            <p>
                {language === 'en'
                    ? 'The Contractor shall monitor platform usage by means of technical measures to ensure system security. This includes in particular the logging of '
                    : 'Der Auftragnehmer überwacht die Plattformnutzung durch technische Maßnahmen, um die Systemsicherheit zu gewährleisten. Dies umfasst insbesondere die Protokollierung von:  '}
            </p>
            <ul >
                <li> {language === 'en' ? 'Unusual access patterns or frequencies ' : 'Ungewöhnlichen Zugriffsmustern oder -frequenzen '}</li>
                <li> {language === 'en' ? 'Abnormal data transfer volumes ' : 'Auffälligen Datenübertragungsvolumina '}</li>
                <li> {language === 'en' ? 'Systematic access attempts to various data areas ' : 'Systematischen Zugriffsversuchen auf verschiedene Datenbereiche'}</li>
                <li> {language === 'en' ? 'Suspicious IP addresses or network activities ' : 'Verdächtigen IP-Adressen oder Netzwerkaktivitäten '}</li>
                <li> {language === 'en' ? 'Unauthorised automation attempts ' : 'Nicht autorisierten Automatisierungsversuchen '}</li>

            </ul>
            <p>
                {language === 'en'
                    ? 'Activities that indicate misuse in accordance with 10.2 are automatically documented and can lead to immediate blocking of access. Blocking is initially temporary to check the facts of the case. If the suspicion of misuse is confirmed, the block can be converted into a permanent block. '
                    : 'Aktivitäten, die auf einen Missbrauch gemäß 10.2 hinweisen, werden automatisch dokumentiert und können zur sofortigen Sperrung des Zugangs führen. Die Sperrung erfolgt zunächst temporär zur Prüfung des Sachverhalts. Bei Bestätigung des Missbrauchsverdachts kann die Sperrung in eine dauerhafte Sperrung umgewandelt werden. '}
            </p>
            <p>
                {language === 'en'
                    ? 'In the event of suspected criminal offences or serious violations of the terms of use, the contractor reserves the right to take legal action and to make relevant documentation available to the law enforcement authorities. '
                    : 'Bei Verdacht auf strafbare Handlungen oder bei schwerwiegenden Verstößen gegen die Nutzungsbedingungen behält sich der Auftragnehmer vor, rechtliche Schritte einzuleiten und relevante Dokumentationen den Strafverfolgungsbehörden zur Verfügung zu stellen. '}
            </p>
            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                11. {language === 'en' ? 'Final provisions ' : 'Schlussbestimmungen'}
            </h2>
            <p>
                11.1 {language === 'en'
                    ? 'The law of the Federal Republic of Germany shall apply to the exclusion of the CISG.  '
                    : 'Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des CISG. '}
            </p><p>
                11.2 {language === 'en'
                    ? 'Should any provision of these GTC be or become invalid, this shall not affect the validity of the remaining GTC.'
                    : 'Sollte eine Bestimmung dieser AGB unwirksam sein oder werden, so wird die Gültigkeit der AGB im Übrigen hiervon nicht berührt. '}
            </p>
            <p>
                11.3 {language === 'en'
                    ? 'The Client shall support the Contractor in the provision of its contractual services by providing reasonable co-operation where necessary. In particular, the Client shall provide the Contractor with the information and data required to fulfil the order. '
                    : 'Der Auftraggeber wird den Auftragnehmer bei der Erbringung seiner vertragsgemäßen Leistungen durch angemessene Mitwirkungshandlungen, soweit erforderlich, fördern. Der Auftraggeber wird insbesondere dem Auftragnehmer die zur Erfüllung des Auftrags erforderlichen Informationen und Daten zur Verfügung stellen. '}
            </p>
            <p>
                11.4  {language === 'en'
                    ? 'If the Client is a merchant, a legal entity under public law or a special fund under public law or has no general place of jurisdiction in Germany, the parties agree that the \Contractor\'s registered office shall be the place of jurisdiction for all disputes arising from this contractual relationship; exclusive places of jurisdiction shall remain unaffected by this. '
                    : 'Sofern der Auftraggeber Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist oder keinen allgemeinen Gerichtsstand in Deutschland hat, vereinbaren die Parteien den Sitz des Auftragnehmers als Gerichtsstand für sämtliche Streitigkeiten aus diesem Vertragsverhältnis; ausschließliche Gerichtsstände bleiben hiervon unberührt. '}
            </p>
            <p>
                11.5  {language === 'en'
                    ? 'The Contractor is authorised to amend these GTC for objectively justified reasons (e.g. changes in case law, the legal situation, market conditions or business or corporate strategy) and subject to a reasonable period of notice. Existing customers will be notified by e-mail at least two weeks before the change comes into effect. If the existing customer does not object within the deadline set in the notification of change, their consent to the change shall be deemed to have been given. If he objects, the changes shall not come into force; in this case, the Contractor shall be entitled to terminate the contract extraordinarily as of the date on which the change comes into force. The notification of the intended amendment to these GTC shall refer to the deadline and the consequences of an objection or failure to object. '
                    : 'Der Auftragnehmer ist berechtigt, diese AGB aus sachlich gerechtfertigten Gründen (z. B. Änderungen in der Rechtsprechung, Gesetzeslage, Marktgegebenheiten oder der Geschäfts- oder Unternehmensstrategie) und unter Einhaltung einer angemessenen Frist zu ändern. Bestandskunden werden hierüber spätestens zwei Wochen vor Inkrafttreten der Änderung per E-Mail benachrichtigt. Sofern der Bestandskunde nicht innerhalb der in der Änderungsmitteilung gesetzten Frist widerspricht, gilt seine Zustimmung zur Änderung als erteilt. Widerspricht er, treten die Änderungen nicht in Kraft; Auftragnehmer ist in diesem Fall berechtigt, den Vertrag zum Zeitpunkt des Inkrafttretens der Änderung außerordentlich zu kündigen. Die Benachrichtigung über die beabsichtigte Änderung dieser AGB wird auf die Frist und die Folgen des Widerspruchs oder seines Ausbleibens hinweisen.  '}
            </p>
            <h2
                style={{
                    color: '#16697A',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                12. {language === 'en' ? 'information on online dispute resolution / consumer arbitration ' : 'Informationen zur Online-Streitbeilegung / Verbraucherschlichtung '}
            </h2>
            <p>
                {language === 'en'
                    ? 'The EU Commission provides a platform for online dispute resolution on the Internet at the following link: https://ec.europa.eu/consumers/odr. This platform serves as a contact point for the out-of-court settlement of disputes arising from online purchases or service contracts in which a consumer is involved. The provider is neither willing nor obliged to participate in a consumer dispute resolution procedure in accordance with the VSBG.'
                    : 'Die EU-Kommission stellt im Internet unter folgendem Link eine Plattform zur Online-Streitbeilegung bereit: https://ec.europa.eu/consumers/odr. Diese Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten aus Online-Kauf oder Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist. Der Anbieter ist weder bereit noch verpflichtet, an einem Verbraucherstreitschlichtungsverfahren nach dem VSBG teilzunehmen.   '}
            </p>
            <p>
                {language === 'en'
                    ? 'Our e-mail address can be found under the heading of these GTC. '
                    : 'Unsere E-Mail-Adresse entnehmen Sie der Überschrift dieser AGB. '}
            </p>
        </div>
    );
};

export default TermsAndConditions;
