import './App.css';
import React from 'react';
import LoginContexProvider from './hooks/customHooks/LoginContexProvider';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/header/Navbar';
import Footer from './components/footer/Footer';
import FAQ from './components/header/FAQ';
import Body from './components/body/Body';
import { Grid } from '@mui/system';
import TermsAndConditions from './utils/TermsAndConditions';
import PrivacyPolicy from './utils/PrivacyPolicy';

function App() {
  return (
    <div className="App">
      <LoginContexProvider>
        <Grid container spacing={0}>
          <Grid size={12}>
            <Navbar />
          </Grid>
          <Grid className="body" size={12}>
            <Routes>
              <Route path="/" element={<Body />} />
              <Route path="/faqs" element={<FAQ />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </Grid >
          <Grid >
            <Footer />
          </Grid>
        </Grid >
      </LoginContexProvider>
    </div>
  );
}

export default App;

