import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { AuthService } from '../../utils/ApiDataHandler';

function ForgotPassword({ onBackToLogin }) {
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleForgotPassword = async (event) => {
        event.preventDefault();
        if (email !== confirmEmail) {
            setErrorMessage("Emails do not match. Please confirm your email.");
            return;
        }

        try {
            const response = await AuthService.forgotPassword(email, confirmEmail);
            if (response.success) {
                setSuccessMessage('Thank you for your request! Please check your email and click on the confirmation link to complete the password reset. If you do nott see the email in your inbox, please check your spam or junk folder.');
                setErrorMessage('');
            } else {
                setErrorMessage('Failed to send password reset instructions. Please try again.');
                setSuccessMessage('');
            }
        } catch (error) {
            setErrorMessage('An error occurred while processing your request.');
            setSuccessMessage('');
        }
    };

    return (
        <Box className="forgot-password-container">
            <Typography variant="h6" gutterBottom sx={{ color: '#16697A', }}>Forgot password  </Typography>
            <form noValidate autoComplete="off" onSubmit={handleForgotPassword}>
                <Box mb={2}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '5px',
                        }}
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="confirmEmail"
                        label="Confirm Email"
                        value={confirmEmail}
                        onChange={(e) => setConfirmEmail(e.target.value)}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '5px',
                        }}
                    />
                </Box>
                {errorMessage && (
                    <Typography color="error" variant="body2">
                        {errorMessage}
                    </Typography>
                )}
                {successMessage && (
                    <Typography sx={{ color: '#16697A', }} variant="body2">
                        {successMessage}
                    </Typography>
                )}
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Button
                        variant="text"
                        onClick={onBackToLogin}
                        sx={{
                            bgcolor: '#16697A',
                            color: '#ffffff',
                            textTransform: 'none',
                            '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                        }}
                    >
                        Back to Login
                    </Button>
                    <Button
                        type="submit"
                        sx={{
                            bgcolor: '#16697A',
                            color: '#ffffff',
                            textTransform: 'none',
                            '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                        }}
                    >
                        Request new password
                    </Button>


                </Box>
            </form>
        </Box>
    );
}

export default ForgotPassword;
