import React, { useContext } from 'react';
import { cardContext } from '../hooks/customHooks/Context';
import "./CustomCard.css";

export const CustomCard = (WrappedComponent) => {
    return function CustomCardWrapper(props) {
        const { heading, description, cardWidth, textAlign } = useContext(cardContext);

        const dynamicWidth = cardWidth || '100%';
        const isDescriptionPresent = !!description;

        return (
            <div
                style={{
                    width: dynamicWidth,
                }}
                className="customCard">
                <div style={{
                    textAlign: textAlign
                }}
                    className={`cardHeader ${isDescriptionPresent ? '' : 'no-description'}`} >
                    <h2 fontSize='30px'>{heading}</h2>
                    {isDescriptionPresent && <p>{description}</p>}
                </div>
                <div className="cardBody">
                    <WrappedComponent {...props} />
                </div>
            </div >
        );
    };
};
