import React, { Fragment, useContext, useEffect } from "react";
import { Button, Slider } from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import Grid from "@mui/material/Grid2";
import { CustomPieChart } from "../../customComponents/CustomPieChart";
import { chartColors } from "../../utils/ProjectDataHandler";
import { cardContext } from '../../hooks/customHooks/Context';
import "./IndicatorWeighting.css";

const IndicatorWeighting = (props) => {
    const indicatorData = props.indicatorData;
    const { setHeading, setCardWidth, setTextAlign, setDescription } = useContext(cardContext);
    useEffect(() => {
        setHeading('Which indicator is how meanningful in this analysis? ');
        setDescription('The following indicators weights have been assigned by scaling experts. You can make adjustments by changing the numerical weights for each indicator. ');
        setCardWidth("100%");
        setTextAlign("left");
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);
    const groupByRequirement = (data) => {
        return data.reduce((acc, curr) => {
            const { requirement_name } = curr;
            if (!acc[requirement_name]) {
                acc[requirement_name] = [];
            }
            acc[requirement_name].push(curr);
            return acc;
        }, {});
    };

    const handleSliderChange = (indicatorId, newValue) => {
        const updatedIndicator = indicatorData.map((indicator) =>
            indicator.indicator_id === indicatorId
                ? { ...indicator, weighting: newValue }
                : indicator
        );
        props.setIndicatorData(updatedIndicator);
    };

    const marks = [
        { value: 1, label: 1 },
        { value: 10, label: 10 },
    ];

    const groupedIndicators = groupByRequirement(indicatorData);

    const pieChartData = indicatorData.map((indicator, index) => ({
        name: `Req ${indicator.indicator_id}`,
        value: indicator.weighting,
        color: chartColors[index % chartColors.length],
    }));

    return (
        <Fragment>
            <Grid container spacing={2} sx={{ minHeight: "48vh", }}>
                <Grid container size={12} className="indicator-list">
                    {Object.keys(groupedIndicators).map((requirementName, reqIndex) => (
                        <Fragment key={requirementName}>

                            <Grid container size={12} sx={{ fontWeight: 'bold', marginTop: 2, color: '#16697A', alignItems: "center", height: 'auto', textAlign: 'left', display: "flex", flexDirection: "row" }}>
                                <Brightness1Icon
                                    sx={{
                                        color: chartColors[(reqIndex) % chartColors.length],
                                        paddingRight: 1,
                                        height: 17,
                                    }}
                                />
                                {requirementName}
                            </Grid>

                            {groupedIndicators[requirementName].map((indicator, index) => (
                                <Grid container size={8} key={indicator.id} spacing={1} justifyContent="space-between">
                                    <Grid size={8} sx={{ display: "flex", flexDirection: "row", textAlign: 'left', paddingLeft: '30px', color: '#489FB5' }}>
                                        {indicator.indicator_name}
                                    </Grid>
                                    <Grid size={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Slider
                                            aria-label="Default"
                                            value={indicator.weighting}
                                            step={1}
                                            min={1}
                                            max={10}
                                            valueLabelDisplay="on"
                                            onChange={(event, newValue) => {
                                                handleSliderChange(indicator.indicator_id, newValue);
                                            }}
                                            sx={{
                                                "& .MuiSlider-thumb": {
                                                    width: 15,
                                                    height: 15,
                                                    color: "#16697A",
                                                },
                                                "& .MuiSlider-track": {
                                                    color: "#489FB5",
                                                },
                                                "& .MuiSlider-rail": {
                                                    color: "#82C0CC",
                                                },
                                                "& .Mui-active": {
                                                    width: 18,
                                                    height: 18,
                                                    color: "#16697A",
                                                },
                                                "& .MuiSlider-valueLabel": {
                                                    backgroundColor: "transparent",
                                                    color: "#16697A",
                                                    fontSize: "12px",
                                                    borderRadius: "4px",
                                                    padding: "0px 0px",
                                                },
                                            }}
                                        //marks={marks}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Fragment>
                    ))}
                </Grid>


            </Grid>
        </Fragment>
    );
};

export default IndicatorWeighting;
