import React, { useState } from 'react';
import './InstructionBox.css';

const instructionsData = {
    1: [
        {
            heading: "Aim & Resources Needed",
            details: "In this first step, your task is to establish the appropriate framework for your scaling decision.",
        },
        {
            heading: "Decision Analytical Background",
            details: "Proper decision framing is crucial to the process. The more precisely you define your question, the more you reduce the complexity of the decision.",
        },
        {
            heading: "Instructions on TechScaler",
            details: "Select the sector and the corresponding subsector in which you position your technology/service from the dropdown menus. \n\nGive your decision-making situation a title and note by when you aim to make the decision.",
        },
    ],
    2: [
        {
            heading: "Aim & Resources Needed",
            details: "In this second step, your task is to identify all the requirements relevant to entering a new market for your product or service. Dedicate at least 60 minutes to this step and approach it with focus and diligence. ",
        },
        {
            heading: "Decision Analytical Background",
            details: "Decision analysis enables its users to make proactive decisions, in contrast to the traditional reactive approach. The key lies in the deliberate and conscious identification of decision-specific goals – in our case here „requirements”. Creating a comprehensive list of these requirements is the most crucial part of the decision-making process. These requirements will later be operationalized through measurable indicators based on reliable data and will serve as the criteria for comparing potential markets worldwide. Furthermore, your requirements will act as benchmarks for evaluating the success of your decision. If your identified requirements are fulfilled, you can consider your decision to be of high quality.",
        },
        {
            heading: "Step by step",
            details:
                <ul>
                    <li>Start with an initial brainstorming session. Freely list all aspects that could theoretically influence your evaluation of potential markets. At this stage, aim to generate as many requirements as possible without judging them immediately.</li>

                    <li> Simultaneously, consider involving experienced team members or external experts and have them conduct their own brainstorming sessions.</li>

                    <li> In the next step, consolidate and refine the results from all brainstorming sessions. Sort through the ideas, eliminate duplicates, and clarify the identified requirements.</li>

                    <li>Finally, proceed to review the provided lists of potential requirements. Select those that align with your previously identified needs, along with any additional ones that you find important. </li>
                </ul>
        },
        {
            heading: "Instructions on TechScaler",
            details: "TechScaler presents you with requirements identified by cleantech experts to support your decision-making process, organized into categories (see the left-hand menu). Begin with the sector-specific requirements. Review the alphabetically ordered list and select the requirements that are most relevant to your target market.When you hover over the info icon, a description of the requirement will appear. After selecting the requirements that are important for your target market within a category, determine whether the respective requirement should be  <strong>maximized or minimized  </strong>for your target market. The default setting is \"maximize\". \n\nContinue working through each category in this manner until you reach \"Legal and Regulatory\". \n\nYou can select up to 20 requirements in total. ",
        },
    ],
    3: [
        {
            heading: "Aim & Resources Needed",
            details: "In this third step, your task is to review your list of requirements for completeness and select the corresponding indicators that will make these requirements measurable",
        },
        {
            heading: "Decision Analytical Background",
            details: "To make the various requirements comparable, they must be translated into numerical values. This is achieved using indicators derived from reliable databases, such as those from the World Bank or the UN. For some (more generic) requirements, TechScaler provides a preselection made by cleantech experts. For others (more specific or customized requirements), the appropriate indicators can be selected separately. TechScaler then converts all chosen indicators into an index for each requirement, ensuring that the values of the requirements are comparable. ",
        },
        {
            heading: "Instructions on TechScaler",
            details: "TechScaler presents your current list of selected requirements, indicating whether they should be maximized or minimized. When you hover over a requirement, a symbol to remove it will appear. By clicking on a requirement, you can view the potential indicators that make each requirement measurable. You can select or deselect indicators and, if desired, adjust the maximize/minimize setting.",
        },
    ],
    4: [
        {
            heading: "Aim & Resources Needed",
            details: "In step 4, your task is to weigh your requirements according to their importance for your decision.",
        },
        {
            heading: "Decision Analytical Background",
            details: "In complex strategic decisions, requirements are not equally weighted—some requirements are more important than others. This step is designed to account for these differences in importance. Keep in mind that TechScaler is comparing for you all potential regions worldwide. This means that the scale for each requirement index always ranges from the lowest-performing region to the highest-performing region. You should consider this when comparing the importance of these indices in this step.",
        },
        {
            heading: "Instructions on TechScaler",
            details: "On the left side, you will see your list of requirements. By default, all requirements are set to 1, meaning they are equally weighted. You can now adjust the importance of each requirement by moving the slider from 1 to a value between 1 and 10. Note: The importance of the requirements is adjusted relative to one another. For example, setting all requirements to 5 means they are equally important to you. However, setting one requirement to 5 while another remains at 1 indicates that the first requirement is five times as important as the second. The pie chart on the right helps you understand and visualize the weighting with percentage values. Continue with indicator weighting.",
        },
    ],
    4.1: [
        {
            heading: "Aim & Resources Needed",
            details: "Your task is to proof the weighting of the indicators according to their importance for operationalizing the requirements. ",
        },
        {
            heading: "Decision Analytical Background",
            details: "Each of your selected requirements is operationalized using valid indicators sourced from reliable databases, such as the World Bank or the UN. These indicators have been compiled and validated by various cleantech and market experts to ensure accuracy in measuring the requirements. Additionally, the experts have determined weights for calculating the indices, which are visualized here. ",
        },
        {
            heading: "Instructions on TechScaler",
            details: "Of course, you can adjust the expert-provided weights based on your personal insights by using the slider to set a value between 1 and 10. The percentages displayed to the right of the slider indicate the selected indicator weights. To adjust the selection of indicators, return to step 3: \"Indicators.\"",
        },
    ],
    5: [
        {
            heading: "Aim & Resources Needed",
            details: "A bar chart displays the results of your decision analysis, as calculated by TechScaler.",
        },
        {
            heading: "Decision Analytical Background",
            details: "In the decision-analytical process, the weighted requirement indices for all regions worldwide are aggregated and compared. This generates a ranking that indicates how well each region meets your defined requirements, as operationalized by the selected indicators.",
        },
        {
            heading: "Instructions on TechScaler",
            details: "The bar chart displays the ranking of potential regions as determined by TechScaler. The numbers within the bars represent the utility values of each region, calculated based on the selected requirements, corresponding indicators, and assigned weights.The chart highlights the 10 regions that best match your criteria, listed in order of fit, starting with the most fitting region. By hovering over the bars and their segments, you can view the weighted individual indices for each requirement. ",
        },
        {
            heading: "Next steps",
            details: (
                <> Take a close look at the top three regions: If you were to begin scaling your technology or service next week, would you choose one of these options? If yes, you’ve reached the end of your decision analysis. In this case, you can download the inputs and results of your analysis as a PDF (see the button). If no, you may need to make some adjustments, which is perfectly normal. Only about 15% of users achieve their desired outcome with the first analysis. You can navigate back and forth in your analysis to make adjustments as needed. Here are some suggestions to refine your process: ,
                    <ul>
                        <li>Consider missing requirements: Check if there are requirements you haven’t included yet. If so, go back and add them to your analysis. Or delete requirements, which doesn’t appear so relevant now that you see the result.  </li>
                        <li>Review the operationalization of requirements: Ensure that you have selected the most appropriate indicators to operationalize your requirements and that the relationship between the indicators is accurate. If needed, go back and make adjustments. </li>
                        <li>Reassess requirements importance: Confirm that your weighting of the requirements reflects their true importance. If not, go back and modify your weightings accordingly. </li>
                    </ul>
                    Note: If necessary, the perspective of a third party could be valuable. In step 1, "Framing," you can invite colleagues or experts to review your decision analysis and make adjustments.
                </>
            ),
        },
    ],
    6: [
        {
            heading: "Aim & Resources Needed",
            details: "In step 6, you can check how sensitive your decision analysis result is to small changes.",
        },
        {
            heading: "Decision Analytical Background",
            details: "The goal of decision analysis is to reduce complexity and minimize risk. TechScaler aims to approximate reality using models and data. Nevertheless, it is useful to assess how robust your result is to small changes in the assumptions you have made. ",
        },
        {
            heading: "Instructions on TechScaler",
            details: "As shown in step 5 “Evaluation”, you can view the ranking of your regions based on expected utility values in the bar chart at the top. Using the sliders, you can adjust the weighting of the requirements and see how the result would change with the newly set weights. The bars in the chart will automatically update when you change the parameters. Your adjustments are not saved, so you can freely modify all parameters without concern. ",
        },
    ],

};


const InstructionBox = ({ currentStep }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed((prev) => !prev);
    };

    const renderDetailsWithBreaks = (text) => {
        if (Array.isArray(text)) {
            return text;
        } else if (typeof text === 'string') {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: text.replace(/\n/g, '<br />'),
                    }}
                />
            );
        } else if (React.isValidElement(text)) {
            return text;
        }
        return null;
    };


    return (
        <div className="instructions-box">
            {!isCollapsed && (
                <div className="instructions-content">
                    {instructionsData[currentStep]?.map((item, index) => (
                        <div key={index} className="instruction-item">
                            <h4>{item.heading}</h4>
                            <p>{renderDetailsWithBreaks(item.details)}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};


export default InstructionBox;
