// RecentProjects.js
import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import "./RecentProjects.css";

function RecentProjects({ projectData, activeProjectId, setActiveProjectId }) {

    return (
        <Grid container rowSpacing={2} className="recent-projects">
            {projectData.length > 0 ? (
                projectData.map((project) => (
                    <Grid size={12} key={project.id}>
                        <Button
                            variant='outlined'
                            className={`project-button ${activeProjectId === project.id ? 'active' : ''}`}
                            onClick={() => setActiveProjectId(project.id)}
                        >
                            {project.title}
                        </Button>
                    </Grid>
                ))
            ) : (
                <Typography>No projects available</Typography>
            )
            }
        </Grid >
    );
}

export default RecentProjects;
