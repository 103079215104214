import React, { useState } from 'react';
import './PasswordForm.css';
import { TextField, InputAdornment, IconButton, Button } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthService } from '../../../utils/ApiDataHandler';

const DeleteAccountForm = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        if (currentPassword === confirmPassword) {
            try {
                await AuthService.DeleteAccount(currentPassword);
                setSuccess("Account deleted successfully.");
                setCurrentPassword('');
                setConfirmPassword('');
            } catch (error) {
                setError("Failed to delete account. Please check your password.");
            }
        } else {
            setError("Passwords do not match.");
        }
    };

    return (
        <div className="password-form">
            <h3>Delete Account</h3>
            <p>
                To delete your account, please enter your current password in both fields to confirm this action.
                This action is irreversible.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="password-group">
                    <TextField
                        label="Current Password"
                        type={showCurrentPassword ? "text" : "password"}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                        fullWidth
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                        edge="end"
                                    >
                                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Confirm Current Password"
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        fullWidth
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {success && <p style={{ color: 'green' }}>{success}</p>}
                <div className="button-group">
                    <Button
                        style={{
                            width: "120px",
                            backgroundColor: '#16697A',
                            color: '#ffffff',
                            textTransform: 'none',
                            borderRadius: '5px',
                        }}
                        type="submit"
                    >
                        Delete Account
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default DeleteAccountForm;
