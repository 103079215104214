import React, { useContext, useEffect, useState } from 'react';
import CustomLoadingSpinner from '../../customComponents/CustomLoadingSpinner';
import { ProjectService } from '../../utils/ApiDataHandler';
import CategoriesList from './CategoriesList';
import { Box, Divider } from '@mui/material';
import { projectContext } from '../../hooks/customHooks/Context';

const CategoriesComponent = ({ selectedCategory, setSelectedCategory, updateRequirementStates, setIsRequirementLoading }) => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { setTotalCategories, setCategoriesData } = useContext(projectContext);

    const categoryValidations = {
        1: [
            "Jane Richardson, Principal Administrator Pesticides, Biotechnology, Food Safety, Environment, Health and Safety Division",
            "Brian Love, CEO, AgScience Insights."
        ],
        2: [
            "Max Gruenig (President, Ecologic Institute, Washington DC): overall review",
            "Viviana Jimenez (Director, Anthesis Group): climate change, oceans",
            "Marc Forni (Lead Disaster Specialist, World Bank): mforni@worldbank.org: natural disasters",

        ],
        3: [
            "Valeria Perotti, Manager, 'Business Ready'-Department, World Bank",
            "Jose Antonio Mejia, Lead Specialist, 'Modernization of the State', Inter-American Development Bank. (Former Vice-President, National Institute of Statistics, Mexico.)",
            "Armando Aliu, Researcher, International Organisation for Migration.",
            "Denise Dias",
            "Laura Brix Newbury",
            "Paul Reynolds"

        ],
        4: [
            "Shlomo Angel, Professor of City Planning, MArron Institute, New York University.",
            "Jonathan Buonocuore, Assistant Professor, Environmental Health. (Former Research Scientist on Environmental Health at Harvard School of Public Health.)",
            "Marie-Claire Sodergren, Senior Economist, Data Production and Analysis Unit, Department of Statistics, International Labor Organization.",
        ],
        5: [
            "Dr Delia Rodrigo, Senior Regulatory Reform Consultant, World Bank.",
            "Gary Kalman, Executive Director, Transparency International US.",
            "Fatema Waheb (Political Affairs Officer, UN Security Council): conflict, security, sanctions",
            "Michael van Gelderen (Human Rights Officer at UN Human Rights): human rights.",
            "Tim Bittiger (Executive Director, Democracy Reporting International): governance, elections.",

        ],
        6: [
            "Prof Nicholas Ashford, Professor of Technology and Policy, Massachusetts Institute of Technology."
        ],
        7: [
            "Delia Rodrigo"
        ]
    };



    const [activeCategory, setActiveCategory] = useState(selectedCategory);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setIsLoading(true);
                const response = await ProjectService.fetchCategories();
                if (response && response.length > 0) {
                    setCategories(response);
                    setCategoriesData(response);
                    setTotalCategories(response.length);

                    if (!selectedCategory) {
                        setActiveCategory(1);
                        setSelectedCategory(1);
                    }
                }
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCategories();
    }, [setSelectedCategory, setCategoriesData, setTotalCategories, selectedCategory]);

    useEffect(() => {
        if (selectedCategory !== activeCategory) {
            setActiveCategory(selectedCategory);
        }
    }, [selectedCategory, activeCategory]);

    const handleOnClickCategory = async (id) => {
        setIsRequirementLoading(true);
        await updateRequirementStates();
        setIsRequirementLoading(false);
        setActiveCategory(id);
        setSelectedCategory(id);
    };

    return isLoading ? (
        <CustomLoadingSpinner />
    ) : (
        <Box display="flex" alignItems="stretch" >
            <Box flexGrow={1} sx={{ width: '35%' }}>
                <CategoriesList
                    categories={categories}
                    activeCategory={activeCategory}
                    onclickCategoryHandler={handleOnClickCategory}
                    categoryValidations={categoryValidations}
                />
            </Box>
            <Divider sx={{ borderColor: '#ffffff', minHeight: '42vh', m: '10px' }} orientation="vertical" flexItem className="vertical-divider" />
        </Box>
    );
};

export default CategoriesComponent;
