import React, { useEffect, useContext } from 'react';
import { loginContext, cardContext } from '../../hooks/customHooks/Context';
import { CustomCard } from '../../customComponents/CustomCard';
import { Button } from '@mui/material';

const FinalStep = () => {
    const { setHeading, setCardWidth, setTextAlign, setDescription } = useContext(cardContext);
    const { setShowOverviewPage } = useContext(loginContext);

    useEffect(() => {
        setHeading('Dashboard ');
        setDescription(' ');
        setCardWidth("100%");
        setTextAlign("left");
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);

    const handleDashboardClick = () => {
        setShowOverviewPage(true);  // Set to true to show the OverviewPage
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '48vh', }}>
            <p>
                Thank you for taking the time to complete the process. Your participation is greatly appreciated.
                get back to your dashboard and download your result.
            </p>

            <Button
                onClick={handleDashboardClick}
                sx={{
                    width: "100px",
                    bgcolor: '#16697A',
                    color: '#ffffff',
                    textTransform: 'none',
                    borderRadius: '5px',
                    '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                }}
            >
                Dashboard
            </Button>
        </div>
    );
};

export default CustomCard(FinalStep);
