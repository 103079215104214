import { Box, Button, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { CustomCard } from '../../customComponents/CustomCard';
import CustomLoadingSpinner from '../../customComponents/CustomLoadingSpinner';
import { cardContext, loginContext, projectContext } from '../../hooks/customHooks/Context';
import { ProjectService } from '../../utils/ApiDataHandler';
import { getMostRecentProjectId } from '../../utils/ProjectDataHandler';
import Typography from '@mui/material/Typography';
import RecentProjects from './RecentProjects';
import "./OverviewPage.css";
import SelectedProjectDataPage from './SelectedProjectDataPage';


function OverviewPage() {
    const { setHeading, setCardWidth, setTextAlign, setDescription } = useContext(cardContext);
    const { setShowOverviewPage } = useContext(loginContext);
    const { projectData, updateProjectData, setSelectedProjectData } = useContext(projectContext);
    const [loading, setLoading] = useState(true);
    const [activeProjectId, setActiveProjectId] = useState(null);


    const newProjectHandler = () => {
        updateProjectData([]);
        setShowOverviewPage(false);
        setSelectedProjectData({
            id: null,
            title: "",
            sector_id: null,
            sub_sector_id: null,
            decision_date: "",
            current_step: 1,
            is_inprogress: true,
            requirements: [],
            countries: [],
            teamMembers: [],
            indicators: [],
            created_at: "",
            updated_at: ""
        });

    }
    useEffect(() => {
        setHeading('Overview');
        setCardWidth("70%");
        setTextAlign("left");
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);

    useEffect(() => {
        const getProjects = async () => {
            try {
                const response = await ProjectService.fetchProjects();
                updateProjectData(response);
                // If there are no projects, automatically go to the new project creation
                if (response === 'No projects found for this user.') {
                    newProjectHandler(); // Navigate to the new project creation view
                    return; // Exit the function
                }
                // Set the most recent project ID after fetching the projects
                const recentProjectId = getMostRecentProjectId(response);
                setActiveProjectId(recentProjectId);
            } catch (error) {
                console.error('Failed to fetch projects:', error);
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };
        getProjects();
    }, [updateProjectData]);

    return (
        <Box sx={{ width: '100%' }} className="grid-container">
            {loading ? (
                <div className="loading-container">
                    <CustomLoadingSpinner loading={true} />
                </div>
            ) : (
                <Grid container spacing={2} >
                    <Grid size={{ xs: 12, md: 12, lg: 3 }} className="project-grid">
                        <Grid size={{ xs: 12, lg: 3 }} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ textAlign: 'left', ml: 2, color: '#16697A', marginBottom: '16px', fontWeight: 'bold' }} variant="h6" gutterBottom>Recent Projects</Typography>
                            <div className="recent-projects-container">
                                <RecentProjects
                                    projectData={projectData}
                                    activeProjectId={activeProjectId}
                                    setActiveProjectId={setActiveProjectId} />
                            </div>
                            <Button className="new-project" sx={{
                                ml: 2,
                                bgcolor: '#16697A',
                                color: '#ffffff',
                                textTransform: 'none',
                                border: '5px solid #ffffff',
                                borderRadius: '5px',
                                '&:hover': { bgcolor: '#16697A', color: '#ffffff', border: '3px solid #ffffff', },
                            }}
                                onClick={newProjectHandler}>Add new Project</Button>
                        </Grid>

                    </Grid>

                    <Divider sx={{ borderColor: '#ffffff' }} orientation="vertical" flexItem className="vertical-divider" />

                    <Grid size={{ xs: 12, md: 12, lg: 8 }}>
                        <SelectedProjectDataPage activeProjectId={activeProjectId} />
                    </Grid>
                </Grid>
            )
            }
        </Box >
    );
}

export default CustomCard(OverviewPage);
