import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider, createTheme, tokens } from "@mui/material/styles";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import moment from 'moment';



const framingContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: '500'
};

const framingTextBoxStyle = {
    width: '35%',
    marginRight: '30px',
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '400',
};

const framingSelectBoxStyle = {
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-start',
    MaxHeight: '40px',
    fontSize: '14px',
    fontWeight: '500'
};


const theme = createTheme({
    components: {
        MuiIconButton: {
            styleOverrides: {
                sizeMedium: {
                    color: "#16697a",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#16697a",
                },
            },
        },
    },
});
export const FramingContainer = ({ children }) => (
    <div style={framingContainerStyle}>{children}</div>
);

export const FramingTextBox = ({ text, error }) => (
    <div
        style={{
            ...framingTextBoxStyle,
            color: error ? 'red' : 'inherit',
        }}
    >
        {text}
    </div>
);


export const SectorSelectBox = ({ sectors, value, handleChange, error }) => (
    <FramingContainer  >
        <FramingTextBox text="Which sector do you work in? " error={!!error} />
        <Box style={framingSelectBoxStyle}>
            <FormControl fullWidth>
                <InputLabel sx={{ alignItems: 'center' }} id="sector-select-label">Sector*</InputLabel>
                <Select
                    labelId="sector-select-label"
                    fullWidth
                    id="sector-select"
                    name="sector"
                    value={value || ""}
                    label="Sector"
                    onChange={handleChange}
                    sx={{
                        backgroundColor: 'white', maxHeight: '40px ',

                    }}
                >
                    {sectors.map((sector) => (
                        <MenuItem key={sector.id} value={sector.id}>
                            {sector.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    </FramingContainer>
);

export const SubSectorSelectBox = ({ subSectors, value, handleChange, error }) => (
    <FramingContainer>
        <FramingTextBox text="What is the specific subsector you work with? " error={!!error} />
        <Box style={framingSelectBoxStyle}>
            <FormControl fullWidth >
                <InputLabel id="subsector-select-label">Subsector*</InputLabel>
                <Select
                    labelId="subsector-select-label"
                    id="subsector-select"
                    name="sub_sector_id"
                    value={value || ""}
                    label="Subsector"
                    onChange={handleChange}
                    sx={{ backgroundColor: 'white', maxHeight: '40px ', }}
                >
                    {subSectors.map((subSector) => (
                        <MenuItem key={subSector.id} value={subSector.id}>
                            {subSector.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    </FramingContainer>
);

export const TitleInput = ({ value, handleChange, error }) => (
    <FramingContainer>
        <FramingTextBox text="What title would you give your decision? " error={!!error} />
        <Box style={framingSelectBoxStyle}>
            <TextField
                fullWidth
                id="outlined-basic"
                label="Title*"
                variant="outlined"
                name="title"
                value={value || ""}
                onChange={handleChange}
                sx={{ maxHeight: '40px ' }}

            />
        </Box>
    </FramingContainer>
);

export const TeamInvolvement = ({ handleOnClickOpen, error }) => (
    <FramingContainer>
        <FramingTextBox text="Who (of your team, experts, etc.) has to get involved? *" error={!!error} />
        <Box style={framingSelectBoxStyle}>
            <PersonAddIcon onClick={handleOnClickOpen} sx={{ maxHeight: '40px', cursor: 'pointer', color: '#16697A' }} />
        </Box>
    </FramingContainer>
);

export const DecisionDatePicker = ({ value, handleDateChange, error }) => (
    <FramingContainer>
        <FramingTextBox text="By what date must your decision be made? " error={!!error} />
        <Box style={framingSelectBoxStyle}>
            <ThemeProvider theme={theme}  >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <DesktopDatePicker
                            label="Decision Date*"
                            value={value ? moment(value) : null}
                            onChange={handleDateChange}
                            slotProps={{ textField: { variant: 'outlined' } }}
                            sx={{
                                backgroundColor: 'white',
                                width: '100%',
                                maxHeight: '40px',
                                borderColor: error ? 'red' : 'inherit',
                            }}
                            disablePast={true}
                        />

                    </div>
                </LocalizationProvider>
            </ThemeProvider>
        </Box>
    </FramingContainer>
);
