import React, { useContext, useState } from 'react';
import { useMediaQuery } from "@mui/material";
import { projectContext } from '../../hooks/customHooks/Context';
import FramingPage from './FramingPage';
import RequirementsPage from './RequirementsPage';
import IndicatorsPage from './IndicatorsPage';
import RegionsPage from './RegionsPage';
import Grid from '@mui/material/Grid2';
import CustomStepper from '../../customComponents/CustomStepper';
import { ProjectService } from '../../utils/ApiDataHandler';
import Weighting from './Weighting';
import "./DecisionSteps.css";
import RobustnessAnalysis from './RobustnessAnalysis';
import EvaluationPage from './EvaluationPage';
import FinalStep from './FinalStep';
import NeedHelpButton from '../../customComponents/NeedHelpButton';
import InstructionBox from '../../customComponents/InstructionBox';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const DecisionSteps = () => {
    const isTablet = useMediaQuery("(max-width: 900px)");
    const { selectedProjectData, setSelectedProjectData } = useContext(projectContext);
    const [currentStep, setCurrentStep] = useState(selectedProjectData.current_step);
    const [showInstructions, setShowInstructions] = useState(true);
    const [isMiniStepActive, setIsMiniStepActive] = useState(false);

    const handleNext = async (projectId) => {
        const updatedProjectId = projectId || selectedProjectData.id;
        setCurrentStep(prevStep => prevStep + 1);
        setSelectedProjectData(prevData => ({
            ...prevData,
            id: updatedProjectId,
            current_step: currentStep + 1,
        }));

        try {
            await ProjectService.updateProject({ ...selectedProjectData, id: updatedProjectId }, updatedProjectId);
        } catch (error) {
            console.error('Failed to update project:', error);
        }
    };

    const handleBack = () => {
        setCurrentStep(prevStep => prevStep - 1);
    };

    const toggleInstructions = () => {
        setShowInstructions(!showInstructions);
    };

    const renderChildComponent = () => {
        switch (currentStep) {
            case 1:
                return <FramingPage handleNext={handleNext} handleBack={handleBack} />;
            case 2:
                return <RequirementsPage handleNext={handleNext} handleBack={handleBack} />;
            case 3:
                return <IndicatorsPage handleNext={handleNext} handleBack={handleBack} />;
            case 4:
                return <Weighting handleNext={handleNext} handleBack={handleBack} isMiniStepActive={isMiniStepActive} setIsMiniStepActive={setIsMiniStepActive} />;
            case 5:
                return <EvaluationPage handleNext={handleNext} handleBack={handleBack} />;
            case 6:
                return <RobustnessAnalysis handleNext={handleNext} handleBack={handleBack} />;
            default:
                return <FinalStep handleNext={handleNext} handleBack={handleBack} />;
        }
    };


    return (
        <div className="decision-steps-container">
            <Grid
                container
                flexDirection={isTablet ? "column" : "row"}
                pl={isTablet ? 2 : 9}
                sx={{ position: 'relative' }}
            >
                <Grid size={{ xs: 12, sm: 14, md: 2 }} >
                    <CustomStepper
                        setActiveStep={setCurrentStep}
                        activeStep={currentStep ? currentStep : 0}
                        direction={isTablet ? "horizontal" : "vertical"}
                    />
                </Grid>

                <Grid
                    size={{ xs: 12, sm: 14, md: showInstructions ? 7.5 : 10 }}
                    sx={{
                        transition: 'all 0.3s ease',
                        paddingRight: isTablet ? 0 : (showInstructions ? 2 : 20)
                    }}
                >
                    {renderChildComponent()}
                </Grid>

                {!isTablet && (
                    <>
                        <IconButton
                            onClick={toggleInstructions}
                            sx={{
                                position: 'absolute',
                                right: showInstructions ? 'auto' : 0,
                                left: showInstructions ? '80%' : 'auto',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                backgroundColor: 'white',
                                '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                },
                                zIndex: 1000,
                                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                            }}
                        >
                            {showInstructions ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>

                        <Grid
                            size={{ xs: 12, sm: 2, md: 2 }}
                            sx={{
                                position: 'fixed',
                                right: 0,
                                top: 68,
                                height: 'calc(100vh - 64px)',
                                maxHeight: '100vh',
                                overflowY: 'auto',
                                transition: 'all 0.3s ease',
                                backgroundColor: showInstructions ? '#16697A' : 'transparent',
                                boxShadow: showInstructions ? '-2px 0 4px rgba(0,0,0,0.1)' : 'none',
                                paddingBottom: '2%'
                            }}
                        >
                            {showInstructions && <InstructionBox currentStep={isMiniStepActive ? 4.1 : currentStep} />}
                        </Grid>
                        <Grid className='container'>
                            <NeedHelpButton currentStep={currentStep} projectId={selectedProjectData.id} />
                        </Grid>
                    </>
                )}

                {isTablet && (
                    <Grid
                        size={{ xs: 12 }}
                        sx={{
                            backgroundColor: '#1b4d5c',
                            color: '#fff',
                            padding: '20px'
                        }}
                    >
                        <InstructionBox currentStep={isMiniStepActive ? 4.1 : currentStep} />
                        <Grid >
                            <NeedHelpButton currentStep={currentStep} projectId={selectedProjectData.id} />
                        </Grid>
                    </Grid>
                )}

            </Grid>




        </div>
    );
};

export default DecisionSteps;