import React, { useContext, useState, useEffect } from 'react';
import LandingPage from './LandingPage';
import HomePage from './HomePage';
import { loginContext } from '../../hooks/customHooks/Context';

function Body() {
    const { showLoginPage, isAuthenticated } = useContext(loginContext);

    const [isSmallScreen, setIsSmallScreen] = useState(false);


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 800) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };

        window.addEventListener('resize', handleResize);


        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderPage = () => {
        if (showLoginPage || isAuthenticated) {
            return <HomePage />;
        }
        return <LandingPage />;
    };

    return (
        <>
            {isSmallScreen && (
                <div style={{ position: 'fixed', top: '10px', left: '10px', backgroundColor: '#FFA62B', padding: '10px', borderRadius: '5px' }}>
                    <p>Please use a larger screen for better visualization.</p>
                </div>
            )}
            {renderPage()}
        </>
    );
}

export default Body;
