import React, { useContext, useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Stack } from '@mui/material';
import { cardContext, projectContext } from '../../hooks/customHooks/Context';
import { ProjectService } from '../../utils/ApiDataHandler';
import { CustomCard } from '../../customComponents/CustomCard';
import IndicatorPopup from './IndicatorPopup';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import "./IndicatorsPage.css";
import SentenceCase from '../../customComponents/SentenceCase';


const IndicatorsPage = (props) => {
    const { totalCategories, selectedProjectData, setSelectedProjectData } = useContext(projectContext);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [groupedRequirements, setGroupedRequirements] = useState({});
    const [openIndicatorPopup, setOpenIndicatorPopup] = useState({});
    const [openLimitPopup, setOpenLimitPopup] = useState(false);
    const [limitPopupMessage, setLimitPopupMessage] = useState('');
    const [requirementsWithoutIndicators, setRequirementsWithoutIndicators] = useState([]);
    const { setHeading, setCardWidth, setTextAlign, setDescription } = useContext(cardContext);
    const [categoriesData, setCategoriesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [highlightRequirements, setHighlightRequirements] = useState([]);

    useEffect(() => {
        setHeading('How can your requirements be measured?');
        setDescription('Below you will see an overview with your selected market requirements. By clicking on a requirement, you can see which indicators have been selected by the scaling experts to operationalize the requirement. You can make adjustments by selecting or deselecting requirements and/or indicators. The databases from which we obtain our data are also made transparent. ');
        setCardWidth("100%");
        setTextAlign("left");
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);

    // Fetch categories
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setIsLoading(true);
                const response = await ProjectService.fetchCategories();
                setCategoriesData(response || []);
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCategories();
    }, []);

    const sortedCategories = categoriesData.sort((a, b) => a.id - b.id);

    const requirementsLengthCheck = () => selectedProjectData.requirements.length <= 20;

    const onClickBack = () => props.handleBack();



    const handleOpenIndicatorPopup = (requirementId) => {
        setOpenIndicatorPopup((prevOpenState) => ({
            ...prevOpenState,
            [requirementId]: true,
        }));
    };

    const handleCloseIndicatorPopup = (requirementId) => {
        setOpenIndicatorPopup((prevOpenState) => ({
            ...prevOpenState,
            [requirementId]: false,
        }));
    };

    const handleCloseLimitPopup = () => {
        setHighlightRequirements(requirementsWithoutIndicators);
        setOpenLimitPopup(false)
    };

    useEffect(() => {
        if (selectedProjectData && selectedProjectData.requirements) {
            const fetchRequirements = async () => {

                const grouped = await selectedProjectData.requirements.reduce(async (accPromise, requirement) => {
                    const acc = await accPromise;
                    const { category_id } = requirement;
                    if (!acc[category_id]) {
                        acc[category_id] = [];
                    }
                    acc[category_id].push(requirement);

                    return acc;
                }, Promise.resolve({}));
                setGroupedRequirements(grouped);
                const requirementsWithoutIndicators = selectedProjectData.requirements.filter(requirement => {
                    const associatedIndicators = selectedProjectData.indicators?.filter(
                        indicator => indicator.requirement_id === requirement.requirement_id
                    );
                    return !associatedIndicators || associatedIndicators.length === 0;
                });
                setRequirementsWithoutIndicators(requirementsWithoutIndicators);

            };
            fetchRequirements();
        }
    }, [selectedProjectData]);

    const onClickNext = () => {
        if (requirementsWithoutIndicators.length > 0) {
            setLimitPopupMessage(
                <Typography variant="body1">
                    {requirementsWithoutIndicators.map((req) => (
                        <div key={req.requirement_id} style={{ marginBottom: '8px' }}>
                            <span style={{ fontWeight: 'bold' }}>• <SentenceCase>{typeof req.requirement_name === 'string' ? req.requirement_name : String(req.requirement_name)}</SentenceCase>
                            </span>
                        </div>
                    ))}
                    <div>
                        {'do not have any indicators selected. Please either select corresponding indicators or remove those requirements from the list to proceed.'}
                    </div>
                </Typography>
            );

            setOpenLimitPopup(true);
        } else if (!requirementsLengthCheck()) {
            setLimitPopupMessage(
                'Requirements cannot exceed 20. Please deselect some requirements before proceeding.'
            );
            setOpenLimitPopup(true);
        } else {
            props.handleNext();
        }
    };

    const handleRemoveRequirement = async (delRequirementID) => {
        try {
            await ProjectService.deleteProjectRequirements(delRequirementID);
            const updatedProjects = await ProjectService.fetchProjectById(selectedProjectData.id);
            setSelectedProjectData(updatedProjects);
        } catch (error) {
            console.error('Error removing requirement:', error);
        }
    };

    return (
        <Box className="requirement-box">
            <Typography
                sx={{

                    color: '#16697A',
                    textAlign: 'right',
                    fontStyle: 'italic',
                }}
            >
                selected Requirements: <strong>{selectedProjectData.requirements.length}</strong>
            </Typography>
            {isLoading ? (
                <Typography variant="h6" align="center">Loading categories...</Typography>
            ) : (
                <Box direction="column" spacing={2} className="selected-requirements">
                    {sortedCategories.map((category) => (
                        <Grid item xs={12} key={category.id} >
                            <h3 className="category-name">{category.name}</h3>
                            {
                                groupedRequirements[category.id] && groupedRequirements[category.id].length > 0 ? (
                                    groupedRequirements[category.id].map((requirement, index) => (
                                        <Box
                                            key={requirement.id}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                border: requirementsWithoutIndicators.some(req => req.id === requirement.id)
                                                    ? highlightRequirements.some(req => req.id === requirement.id)
                                                        ? '1.5px solid red'
                                                        : '1.5px solid #16697A'
                                                    : '1.5px solid #16697A',
                                                borderRadius: '5px',
                                                marginBottom: '8px',
                                                padding: '10px',
                                                backgroundColor: '#f9f9f9',
                                                color: '#000000',
                                                position: 'relative',
                                                height: 'auto',


                                                '&:hover': {
                                                    backgroundColor: '#16697A',
                                                    color: '#ffffff',

                                                    '.remove-btn': {
                                                        display: 'inline-flex',

                                                    },
                                                    '.MuiDivider-root': {
                                                        borderColor: '#ffffff',
                                                    },
                                                },
                                            }}
                                            onClick={() => handleOpenIndicatorPopup(requirement.requirement_id)}
                                        >
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    {requirement.priority === 1 ? 'Max' : 'Min'}
                                                </Stack>
                                                <Divider orientation="vertical" flexItem sx={{ borderColor: '#16697A', height: 'auto' }} />
                                                <Stack direction="row" spacing={1} alignItems="left">
                                                    <Typography align="left" sx={{ textAlign: 'left' }}> <SentenceCase>{requirement.requirement_name}</SentenceCase></Typography>
                                                </Stack>
                                            </Stack>

                                            <IndicatorPopup
                                                open={openIndicatorPopup[requirement.requirement_id] || false}
                                                onClose={() => handleCloseIndicatorPopup(requirement.requirement_id)}
                                                requirementData={requirement}
                                                indicatorData={requirement.indicators}
                                                categoriesData={categoriesData}
                                            />
                                            <Button
                                                className="remove-btn"
                                                variant="contained"
                                                size="small"
                                                color="error"
                                                sx={{
                                                    display: 'none',
                                                    textTransform: 'none',
                                                    maxHeight: '2.5vh',
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveRequirement(requirement.id);
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography
                                        sx={{
                                            fontStyle: 'italic',
                                            padding: '8px 0',
                                            textAlign: 'left'
                                        }}
                                    >
                                        {`No requirements selected in ${category.name} category`}
                                    </Typography>
                                )}
                        </Grid>
                    ))}
                </Box>
            )}

            <Dialog open={openLimitPopup} onClose={handleCloseLimitPopup}>
                <DialogTitle>Validation required</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {limitPopupMessage}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseLimitPopup} sx={{
                        width: "100px",
                        bgcolor: '#16697A',
                        color: '#ffffff',
                        textTransform: 'none',
                        borderRadius: '5px',
                    }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', columnGap: '20px' }}>
                <Button onClick={onClickBack} sx={{
                    width: "100px",
                    bgcolor: '#ffffff',
                    color: '#16697A',
                    textTransform: 'none',
                    borderRadius: '5px',
                    '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                }}>Back</Button>
                <Button onClick={onClickNext} sx={{
                    width: "100px",
                    bgcolor: '#16697A',
                    color: '#ffffff',
                    textTransform: 'none',
                    borderRadius: '5px',
                    '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                }}>Next</Button>
            </div>
        </Box>
    );
};

export default CustomCard(IndicatorsPage);
