import React from "react";
import Stepper from "@mui/material/Stepper";
import "./CustomStepper.css";
import { Step, StepLabel } from "@mui/material";

const steps = [
    "Framing",
    "Requirements",
    "Indicators",
    //"Regions",
    "Weighting",
    "Evaluation",
    "Robustness Analysis",
];

const CustomStepper = (props) => {
    return (
        <Stepper className="Stepper" orientation={props.direction} activeStep={props.activeStep - 1}>
            {steps.map((step, index) => (
                <Step
                    className={props.activeStep > index + 1 ? "Step completed" : props.activeStep === index + 1 ? "Step active" : "Step"}
                    key={index}
                    onClick={() => {
                        if (index < props.activeStep) props.setActiveStep(index + 1);
                    }}
                >
                    <StepLabel className="StepLabel">{step}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default CustomStepper;
