import React, { Fragment, useContext, useState } from "react";
import { Button, Slider } from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import Grid from "@mui/material/Grid2";
import { CustomPieChart } from '../../customComponents/CustomPieChart';
import { chartColors } from "../../utils/ProjectDataHandler";
import "./RequirementWeighting.css";
import { ProjectService } from "../../utils/ApiDataHandler";
import { projectContext } from "../../hooks/customHooks/Context";

const RequirementWeighting = (props) => {
    const requirementData = props.requirementData;
    const { categoriesData } = useContext(projectContext)



    const handleSliderChange = (requirementId, newValue) => {
        const updatedRequirements = requirementData.map((requirement) =>
            requirement.requirement_id === requirementId
                ? { ...requirement, weighting: newValue }
                : requirement
        );
        props.setRequirementData(updatedRequirements);
    };


    const marks = [
        { value: 1, label: 1 },
        { value: 10, label: 10 },
    ];


    const pieChartData = requirementData.map((requirement, index) => ({
        name: `Req ${requirement.requirement_id}`,
        value: requirement.weighting,
        color: chartColors[index % chartColors.length],
    }));


    return (
        <Fragment>
            <Grid container spacing={2} sx={{ minHeight: "48vh", }}>
                <Grid container size={6} className="requirement-list">
                    {requirementData.sort((a, b) => a.requirement_id - b.requirement_id).map((requirement, index) => (
                        <Grid container size={10} key={requirement.requirement_id} >
                            <Grid size={8} sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: 'auto', textAlign: 'left', fontSize: '12px' }}>
                                <Brightness1Icon
                                    sx={{
                                        color: chartColors[index % chartColors.length],
                                        paddingRight: 1,
                                        alignItems: 'center',
                                        height: 17,
                                    }}
                                />
                                {requirement.requirement_name}
                            </Grid>
                            <Grid size={4}>
                                <Slider
                                    aria-label="Default"
                                    value={requirement.weighting}
                                    step={1}
                                    min={1}
                                    max={10}
                                    valueLabelDisplay="on"
                                    onChange={(event, newValue) => {
                                        handleSliderChange(requirement.requirement_id, newValue);
                                    }}
                                    sx={{
                                        "& .MuiSlider-thumb": {
                                            width: 15,
                                            height: 15,
                                            color: "#16697A",
                                        },
                                        "& .MuiSlider-track": {
                                            color: "#489FB5",
                                        },
                                        "& .MuiSlider-rail": {
                                            color: "#82C0CC",
                                        },
                                        "& .Mui-active": {
                                            width: 18,
                                            height: 18,
                                            color: "#16697A",
                                        },
                                        "& .MuiSlider-valueLabel": {
                                            backgroundColor: "transparent",
                                            color: "#16697A",
                                            fontSize: "12px",
                                            borderRadius: "4px",
                                            padding: "0px 0px",
                                        },
                                    }}
                                // marks={marks}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                <Grid container size={5}>
                    <Grid size={8}>



                        <Button
                            className="custom-button"
                            onClick={() => {
                                props.setIsShowIndicatorWeighting();
                            }}
                            sx={{
                                marginRight: '-190%',
                                color: "#313638",
                                borderColor: "#16697a",
                                backgroundColor: "#FFA62B",
                                textTransform: 'none',

                                width: '60%',

                                "&:hover": {
                                    borderColor: "#16697a",
                                    backgroundColor: "#FFA62B",
                                }
                            }}
                        >
                            Indicator weighting
                        </Button>
                        <CustomPieChart
                            data={pieChartData}
                            width="510"
                        />
                    </Grid>
                </Grid>



            </Grid>
        </Fragment>
    );
};

export default RequirementWeighting;
