// ProjectContextProvider.js
import React, { useState, useCallback } from 'react';
import { projectContext } from './Context';

export const ProjectContextProvider = ({ children }) => {
    const [projectData, setProjectData] = useState(null);
    const [selectedProjectData, setSelectedProjectData] = useState();
    const [totalCategories, setTotalCategories] = useState();
    const [categoriesData, setCategoriesData] = useState();
    const updateProjectData = useCallback((data) => {
        setProjectData(data);
    }, []);

    const contextValue = {
        projectData,
        updateProjectData,
        selectedProjectData,
        setSelectedProjectData,
        totalCategories,
        setTotalCategories,
        setCategoriesData,
        categoriesData


    }
    return (
        <projectContext.Provider value={contextValue} >
            {children}
        </projectContext.Provider >
    );
};
