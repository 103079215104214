import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ProjectService } from '../../utils/ApiDataHandler';
import './CompanyProfile.css';
import { CustomCard } from '../../customComponents/CustomCard';
import { cardContext } from '../../hooks/customHooks/Context';

const useFetchData = () => {
    const [revenues, setRevenues] = useState([]);
    const [positions, setPositions] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [revenuesData, positionsData, industriesData] = await Promise.all([
                    ProjectService.fetchRevenues(),
                    ProjectService.fetchPositions(),
                    ProjectService.fetchIndustries()
                ]);

                setRevenues(revenuesData);
                setPositions(positionsData);
                setIndustries(industriesData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
                setError('Failed to fetch data');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { revenues, positions, industries, loading, error };
};


const CompanyProfile = ({ onSubmitSuccess }) => {
    const { setHeading, setCardWidth, setTextAlign, setDescription } = useContext(cardContext);
    const [selectedSection, setSelectedSection] = useState('PersonalData');
    useEffect(() => {
        setHeading('Please complete your profile');
        setDescription('');
        setCardWidth("60%");
        setTextAlign("left");
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);

    const [formData, setFormData] = useState({
        linkedin: '',
        companyName: '',
        industries: [],
        otherIndustry: '',
        adress: '',
        position: '',
        numOfEmployees: '',
        revenue: '',
        orgType: '',
        companyWebsite: ''
    });

    const { revenues, positions, industries } = useFetchData();

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);

    const handleIndustryChange = useCallback((e) => {
        const selectedIndustries = Array.from(e.target.selectedOptions, option => option.value);
        setFormData(prevState => ({
            ...prevState,
            industries: selectedIndustries
        }));
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        const {
            companyName,
            linkedin,
            numOfEmployees,
            revenue,
            industries,
            position,
            orgType,
            companyWebsite,
            otherIndustry
        } = formData;

        // Create the payload object to send to the API
        const payload = {
            company_name: companyName,
            linkedIn_url: linkedin,
            no_of_employees: numOfEmployees,
            revenue_id: revenue,
            industry_id: industries,
            position_id: position,
            organization_type: orgType,
            custom_industry: "not otherIndustry",
            website_url: companyWebsite
        };

        try {

            const response = await ProjectService.submitCompanyProfile(payload);
            onSubmitSuccess();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} >
            <h2>Company Information</h2>
            <div className="formRow">
                <div className="formGroup halfWidth">
                    <FormGroup
                        label="Personal linkedIn url"
                        name="linkedin"
                        type="url"
                        value={formData.linkedin}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup halfWidth">
                    <FormGroup
                        label="Adress"
                        name="adress"
                        type="text"
                        value={formData.adress}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="formRow">
                <div className="formGroup halfWidth">
                    <FormGroup
                        label="Company name*"
                        name="companyName"
                        type="text"
                        value={formData.companyName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="formGroup quarterWidth">
                    <FormGroup
                        label="Number of employees"
                        name="numOfEmployees"
                        type="select"
                        value={formData.numOfEmployees}
                        onChange={handleChange}
                        options={[
                            { value: '', label: 'Select' },
                            { value: '1-10', label: '1-10' },
                            { value: '11-50', label: '11-50' },
                            { value: '51-200', label: '51-200' },
                            { value: '201-500', label: '201-500' },
                            { value: '501-1000', label: '501-1000' },
                            { value: '1001-5000', label: '1001-5000' },
                            { value: '5001-10000', label: '5001-10000' },
                            { value: '10000+', label: '10000+' },
                        ]}
                    />
                </div>
                <div className="formGroup quarterWidth">
                    <FormGroup
                        label="Revenue"
                        name="revenue"
                        type="select"
                        value={formData.revenue}
                        onChange={handleChange}
                        options={[
                            { value: '', label: 'Select' },
                            ...revenues.map(revenue => ({
                                value: revenue.id,
                                label: revenue.name,
                            })),
                        ]}
                    />
                </div>
            </div>

            <div className="formRow">
                <div className="formGroup quarterWidth">
                    <FormGroup
                        label="Industry*"
                        name="industries"
                        type="select"
                        // isMultiSelect={true}
                        value={formData.industries}
                        onChange={handleIndustryChange}
                        required
                        options={[
                            { value: '', label: 'Select', disabled: true },
                            ...industries.map(industry => ({
                                value: industry.id,
                                label: industry.name
                            }))
                        ]}
                    />
                </div>
                <div className="formGroup quarterWidth">
                    <FormGroup
                        label="Position*"
                        name="position"
                        type="select"
                        value={formData.position}
                        onChange={handleChange}
                        required
                        options={[
                            { value: '', label: 'Select' },
                            ...positions.map(position => ({
                                value: position.id,
                                label: position.name
                            }))
                        ]}
                    />
                </div>
                <div className="formGroup quarterWidth">
                    <FormGroup
                        label="Organization type"
                        name="orgType"
                        type="select"
                        value={formData.orgType}
                        onChange={handleChange}
                        options={[
                            { value: '', label: 'Select' },
                            { value: 'Public Company', label: 'Public Company' },
                            { value: 'Self-Employed', label: 'Self-Employed' },
                            { value: 'Government Agency', label: 'Government Agency' },
                            { value: 'Nonprofit', label: 'Nonprofit' },
                            { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
                            { value: 'Partnership', label: 'Partnership' },
                            { value: 'Privately Held', label: 'Privately Held' },
                            { value: 'Educational Institution', label: 'Educational Institution' },
                            { value: 'Not yet registered', label: 'Not yet registered' }
                        ]}
                    />
                </div>

                <div className="formGroup quarterWidth">
                    <FormGroup
                        label="Company website"
                        name="companyWebsite"
                        type="url"
                        value={formData.companyWebsite}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="button">
                <button className="submitButton" type="submit">Save</button>
            </div>
        </form>
    );
}


const FormGroup = ({ label, name, type, value, onChange, options, required, placeholder, isMultiSelect, children }) => (
    <div className="formGroup">
        <label htmlFor={name}>{label}</label>
        {type === 'select' ? (
            <select
                name={name}
                id={name}
                value={value}
                onChange={onChange}
                required={required}
                multiple={isMultiSelect}
            >
                {options.map(option => (
                    <option key={option.value} value={option.value} disabled={option.disabled || false}>
                        {option.label}
                    </option>
                ))}
            </select>
        ) : (
            <input
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required={required}
            />
        )}
        {children}
    </div>
);

export default CustomCard(CompanyProfile);
