import React from "react";
import { chartColors } from "../utils/ProjectDataHandler";
import Chart from "react-apexcharts";
export const CustomPieChart = (props) => {
    let seriesData = [];
    let labels = [];
    let colors = chartColors;
    props.data.forEach((data) => {
        seriesData.push(parseFloat(data.value));
        labels.push(data.name);
        colors.push(data.color);
    });
    return (
        <Chart
            options={{
                chart: {
                    background: "transparent",
                },
                stroke: {
                    width: 0, // Disable border lines 
                    colors: ["transparent"], // Make borders transparent
                },
                tooltip: { enabled: false },
                colors: colors,
                legend: { show: false },

            }}
            series={seriesData}
            type="pie"
            width="150%"

        />
    );
};


