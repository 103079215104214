import React, { useState } from "react";
import { Box, Button, Link, Popover, TextField, Typography } from "@mui/material";
import { ProjectService } from "../utils/ApiDataHandler";
import "./NeedHelpButton.css"

const NeedHelpButton = ({ currentStep, projectId }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSendMessage = async () => {
        if (!message.trim()) {
            setError("Message cannot be empty.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await ProjectService.needHelp(projectId, message, currentStep); // Call the service
            setMessage(""); // Clear the message input
            handleClose(); // Close the popover
        } catch (error) {
            console.error("Message is not sent:", error);
            setError("Failed to send the message. Please try again.");
        } finally {
            setLoading(false);
        }
    };


    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div className='container'>
            <Link
                onClick={handleClick}
                sx={{
                    color: '#ffffff',
                    textTransform: 'none',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    display: 'inline-block',
                    '&:hover': { color: '#ffffff' },
                }}
            >
                Need more help?
            </Link>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box p={2}>
                    <Typography variant="h6">Need Help in Step {currentStep}?</Typography>
                    <Typography variant="body2">
                        If you have any questions or need assistance, let us know.
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>Message:</Typography>
                    <TextField
                        multiline
                        rows={4}
                        variant="outlined"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        sx={{ my: 2, width: "100%" }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSendMessage}
                        sx={{
                            bgcolor: '#16697A',
                            color: '#ffffff',
                            textTransform: 'none',
                            borderRadius: '5px',
                            '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                        }}
                    >
                        Send message
                    </Button>
                </Box>
            </Popover>
        </div>
    );
};

export default NeedHelpButton;
