import { Button, Select, TextField, MenuItem } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { loginContext } from '../../../hooks/customHooks/Context';
import { ProjectService } from '../../../utils/ApiDataHandler';
import './CompanyDataForm.css';

const CompanyDataForm = () => {
    const { companyData, setCompanyData } = useContext(loginContext);

    // State for editable fields
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState('');
    const [industry, setIndustry] = useState('');
    const [industryOptions, setIndustryOptions] = useState([]);
    const [numberOfEmployees, setNumberOfEmployees] = useState('');
    const [revenue, setRevenue] = useState('');
    const [organizationType, setOrganizationType] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [address, setAddress] = useState('');

    // Dropdown data
    const [revenues, setRevenues] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [positions, setPositions] = useState([]);

    // Options for number of employees dropdown
    const employeeOptions = [
        { value: '', label: 'Select' },
        { value: '1-10', label: '1-10' },
        { value: '11-50', label: '11-50' },
        { value: '51-200', label: '51-200' },
        { value: '201-500', label: '201-500' },
        { value: '501-1000', label: '501-1000' },
        { value: '1001-5000', label: '1001-5000' },
        { value: '5001-10000', label: '5001-10000' },
        { value: '10000+', label: '10000+' },
    ];

    const commonStyle = {
        height: '40px',
        fontSize: '14px',
        borderRadius: '5px',
        boxSizing: 'border-box',
        width: '100%'
    };
    // Fetch company details and dropdown data on mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ProjectService.fetchCompanyDetails();
                if (response.success) {
                    setCompanyData(response.data);
                    // Set initial values based on response data
                    setName(response.data.company_name);
                    setIndustry(response.data.industries ? response.data.industries[0].id : '');
                    setNumberOfEmployees(response.data.no_of_employees);
                    setRevenue(response.data.revenue_id);
                    setOrganizationType(response.data.organization_type);
                    setCompanyWebsite(response.data.website_url);
                    setAddress(response.data.address);
                }
            } catch (error) {
                console.error('Error fetching company details:', error);
            }
        };

        const fetchDropdownData = async () => {
            try {
                const revenues = await ProjectService.fetchRevenues();
                setRevenues(revenues);

                const industries = await ProjectService.fetchIndustries();
                setIndustries(industries);

                const positions = await ProjectService.fetchPositions();
                setPositions(positions);
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };

        fetchData();
        fetchDropdownData();
    }, [setCompanyData]);

    const handleEdit = () => setIsEditing(true);

    const handleSave = async () => {

        const updatedCompanyData = {
            company_name: name,
            industry_id: [industry],
            no_of_employees: numberOfEmployees.toString(),
            revenue_id: revenue,
            position_id: companyData.position_id,
            organization_type: organizationType,
            website_url: companyWebsite
                ? (companyWebsite.startsWith('http')
                    ? companyWebsite
                    : `https://${companyWebsite}`)
                : '',

            address: address,
        };

        try {
            const response = await ProjectService.updateCompanyProfile(updatedCompanyData);
            if (response.success) {
                setCompanyData(response.data);
                setIsEditing(false);
            } else {
                console.error('Failed to update company profile:', response.message);
            }
        } catch (error) {
            console.error('Error updating company profile:', error);
        }
    };

    const getRevenueName = (id) => {
        const revenueItem = revenues.find((rev) => rev.id === id);
        return revenueItem ? revenueItem.name : '';
    };

    return (
        <div className="company-data">
            <h3>Company Data</h3>
            <div className="form-group form-grid">
                <div className="form-field">
                    <label>Name:</label>
                    {isEditing ? (
                        <TextField variant="outlined" sx={commonStyle} value={name} onChange={(e) => setName(e.target.value)} />
                    ) : (
                        <span>{name}</span>
                    )}
                </div>

                <div className="form-field">
                    <label>Industry*</label>
                    {isEditing ? (
                        <Select
                            value={industry}
                            onChange={(e) => setIndustry(e.target.value)}
                            displayEmpty

                            sx={commonStyle}
                        >
                            {industries.map((ind) => (
                                <MenuItem key={ind.id} value={ind.id}>
                                    {ind.name}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <span>{industries.find((ind) => ind.id === industry)?.name || ''}</span>
                    )}
                </div>

                <div className="form-field">
                    <label>Number of Employees:</label>
                    {isEditing ? (
                        <Select
                            value={numberOfEmployees}
                            onChange={(e) => setNumberOfEmployees(e.target.value)}
                            displayEmpty

                            sx={commonStyle}
                        >
                            {employeeOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <span>{numberOfEmployees}</span>
                    )}
                </div>

                <div className="form-field">
                    <label>Revenue:</label>
                    {isEditing ? (
                        <Select
                            value={revenue}
                            onChange={(e) => setRevenue(e.target.value)}
                            displayEmpty

                            sx={commonStyle}
                        >
                            {revenues.map((rev) => (
                                <MenuItem key={rev.id} value={rev.id}>
                                    {rev.name}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <span>{revenues.find((rev) => rev.id === revenue)?.name || revenue}</span>
                    )}
                </div>

                <div className="form-field">
                    <label>Organization Type:</label>
                    {isEditing ? (
                        <Select
                            value={organizationType || ''}
                            onChange={(e) => setOrganizationType(e.target.value)}

                            displayEmpty
                            sx={commonStyle}
                        >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="Public Company">Public Company</MenuItem>
                            <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                            <MenuItem value="Government Agency">Government Agency</MenuItem>
                            <MenuItem value="Nonprofit">Nonprofit</MenuItem>
                            <MenuItem value="Sole Proprietorship">Sole Proprietorship</MenuItem>
                            <MenuItem value="Partnership">Partnership</MenuItem>
                            <MenuItem value="Privately Held">Privately Held</MenuItem>
                            <MenuItem value="Educational Institution">Educational Institution</MenuItem>
                            <MenuItem value="Not yet registered">Not yet registered</MenuItem>
                        </Select>
                    ) : (
                        <span>{organizationType || 'Not specified'}</span>
                    )}
                </div>

                <div className="form-field">
                    <label>Company Website:</label>
                    {isEditing ? (
                        <TextField
                            variant="outlined"
                            value={companyWebsite}
                            onChange={(e) => setCompanyWebsite(e.target.value)}
                            sx={commonStyle}

                        />
                    ) : (
                        <span>{companyWebsite}</span>
                    )}
                </div>


                <div className="form-field">
                    <label>Address:</label>
                    {isEditing ? (
                        <TextField variant="outlined" sx={commonStyle} value={address} onChange={(e) => setAddress(e.target.value)} />
                    ) : (
                        <span>{address}</span>
                    )}
                </div>
            </div>

            <div className="button-group">
                <Button
                    onClick={isEditing ? handleSave : handleEdit}
                    style={{
                        width: '100px',
                        backgroundColor: '#16697A',
                        color: '#ffffff',
                        textTransform: 'none',
                        borderRadius: '5px',
                    }}
                >
                    {isEditing ? 'Save' : 'Change'}
                </Button>
            </div>
        </div>
    );
};

export default CompanyDataForm;
