import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Button, Typography, Card, CardContent, Divider, Stepper, Step, StepLabel } from '@mui/material';
import Grid from '@mui/material/Grid2'
import { projectContext, loginContext } from '../../hooks/customHooks/Context';
import { getProjectTitleData } from '../../utils/ProjectDataHandler';
import CustomLoadingSpinner from '../../customComponents/CustomLoadingSpinner';
import { ProjectService } from '../../utils/ApiDataHandler';
import "./SelectedProjectDataPage.css";

const SelectedProjectDataPage = ({ activeProjectId }) => {
    const steps = Array.from({ length: 6 }, (_, i) => i + 1);
    const CustomStepIcon = ({ active, icon }) => (
        <div className={`custom-step-icon-root ${active ? 'custom-step-icon-active' : 'custom-step-icon-inactive'}`}>
            <div className="custom-step-icon-circle">{icon}</div>
        </div>
    );

    const { projectData, selectedProjectData, setSelectedProjectData } = useContext(projectContext);
    const { setShowOverviewPage } = useContext(loginContext);
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        if (projectData && activeProjectId) {
            setSelectedProjectData(getProjectTitleData(projectData, activeProjectId));
        }
    }, [projectData, activeProjectId, setSelectedProjectData]);

    const downloadReport = async (projectId) => {
        setIsDownloading(true);
        try {
            await ProjectService.downloadReport(projectId);
        } catch (error) {
            console.error('Failed to download the report:', error);
        } finally {
            setIsDownloading(false);
        }

    };

    if (!selectedProjectData) return <CustomLoadingSpinner />;
    return (
        <Fragment>
            <Grid container >
                <Typography variant="h6" sx={{ textAlign: 'left', color: '#16697A', marginBottom: '16px', fontWeight: 'bold' }} gutterBottom>Project "{selectedProjectData.title}" Overview</Typography>
                <Card className="project-card" sx={{ overflow: 'auto' }}>
                    <CardContent className="project-card-content">
                        <Grid container className="grid-container">
                            <Grid size={{ xs: 6 }} className="grid-item left-align">
                                <Typography variant="body1">Deadline</Typography>
                                <Typography variant="body1" sx={{ color: '#16697A' }}>{selectedProjectData.decision_date}</Typography>
                            </Grid>
                            <Grid size={{ xs: 6 }} className="grid-item right-align">
                                <Typography variant="body1">Team Members</Typography>
                                <Typography variant="body1" sx={{ color: '#16697A' }}>
                                    {selectedProjectData.teamMembers.map(member => (
                                        <span key={member.id} href="/team">{member.first_name} {member.last_name}, </span>
                                    ))}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider className="divider" />
                        <Grid container spacing={2} className="grid-container" sx={{ paddingTop: '20px' }}>
                            <Grid size={{ xs: 6 }} className="grid-item left-align">
                                <Typography variant="body1">Progress</Typography>
                                <Stepper alternativeLabel activeStep={selectedProjectData.current_step - 1} connector={null}>
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepLabel
                                                StepIconComponent={(props) => (
                                                    <CustomStepIcon
                                                        {...props}
                                                        active={index < selectedProjectData.current_step}
                                                        icon={label}
                                                    />
                                                )}
                                            />
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>
                            <Grid size={{ xs: 2 }} >
                                <Button variant="contained" color="primary" className="continue-button" onClick={() => { setShowOverviewPage(false) }}
                                    sx={{ ml: 2, bgcolor: '#16697A', color: '#ffffff', width: '100%', textTransform: 'none', '&:hover': { bgcolor: '#16697A', color: '#ffffff', } }}>
                                    Continue
                                </Button>
                            </Grid>
                        </Grid>


                        <Grid container spacing={2} className="grid-container" sx={{ paddingTop: '20px' }}>
                            <Grid size={{ xs: 6 }} className="grid-item left-align">
                                <Typography variant="body1">Result</Typography>
                            </Grid>
                            <Grid size={{ xs: 2 }} className="grid-item button-align">
                                <Button variant="contained" onClick={() => downloadReport(selectedProjectData.id)} disabled={selectedProjectData.current_step <= 4 || isDownloading} className="download-button" sx={{ ml: 2, width: '100%', bgcolor: '#16697A', color: '#ffffff', textTransform: 'none', '&:hover': { bgcolor: '#16697A', color: '#ffffff', } }}>{isDownloading ? 'Downloading...' : 'Download'}</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-container" sx={{ paddingTop: '20px' }}>
                            <Grid size={{ xs: 6 }} className="grid-item left-align">
                                <Typography variant="body1">Raw data</Typography>
                            </Grid>
                            <Grid size={{ xs: 2 }} className="grid-item button-align">
                                <Button variant="contained" disabled={true} className="download-button" sx={{ ml: 2, width: '100%', bgcolor: '#16697A', color: '#ffffff', textTransform: 'none', '&:hover': { bgcolor: '#16697A', color: '#ffffff', } }} >Get access</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-container" sx={{ paddingTop: '20px' }}>
                            <Grid size={{ xs: 6 }} className="grid-item left-align">
                                <Typography variant="body1">Request consultation</Typography>
                            </Grid>
                            <Grid size={{ xs: 2 }} className="grid-item button-align">
                                <Button variant="contained" disabled={true} className="download-button" sx={{ ml: 2, width: '100%', bgcolor: '#16697A', color: '#ffffff', textTransform: 'none', '&:hover': { bgcolor: '#16697A', color: '#ffffff', } }}>Get support</Button>
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>

            </Grid>

        </Fragment >
    )
}

export default SelectedProjectDataPage