import React, { useState } from "react";
import {
    Modal,
    Box,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Edit, Delete } from "@mui/icons-material";
import "./AddTeamMemberPopup.css";

const AddTeamMemberPopup = ({ open, handleClose, members, setMembers }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [editIndex, setEditIndex] = useState(-1);
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
    });
    const validate = () => {
        let isValid = true;
        const newErrors = { firstName: "", lastName: "", email: "" };

        if (!firstName) {
            newErrors.firstName = "First name is required";
            isValid = false;
        }

        if (!lastName) {
            newErrors.lastName = "Last name is required";
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            newErrors.email = "A valid email is required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleAddOrUpdate = () => {
        if (!validate()) return;

        const newTeammate = { first_name: firstName, last_name: lastName, email };

        if (editIndex >= 0) {
            const updatedTeammates = [...members];
            updatedTeammates[editIndex] = newTeammate;
            setMembers(updatedTeammates);
            setEditIndex(-1);
        } else {
            setMembers([...members, newTeammate]);
        }

        setFirstName("");
        setLastName("");
        setEmail("");
    };

    const handleEdit = (index) => {
        const teamMember = members[index];
        setFirstName(teamMember.first_name);
        setLastName(teamMember.last_name);
        setEmail(teamMember.email);
        setEditIndex(index);
    };

    const handleDelete = (index) => {
        const updatedTeammates = members.filter((_, i) => i !== index);
        setMembers(updatedTeammates);
    };

    const handleSave = () => {
        const formattedTeamMembers = { members: members };
        setMembers(formattedTeamMembers);
        handleClose();
    };

    return (
        <Modal
            open={open}
            onClose={() => { setMembers(members); handleClose(); }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                className="add-list"
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 800,
                    height: 500,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <h2 style={{ color: "#1c5a73" }} id="modal-title">
                    Add a team member to your project
                </h2>
                <p style={{ color: "#1c5a73" }} id="modal-description">
                    To add a new team member to this project, please enter their email
                    address below. An invitation will be sent to them, allowing them to
                    join and collaborate with the team. Ensure the email address is
                    correct to avoid any delays. Once they accept the invitation, they'll
                    have access to all the project resources and be able to contribute
                    effectively.
                </p>
                {members.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {members.map((teamMember, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{teamMember.first_name}</TableCell>
                                        <TableCell>{teamMember.last_name}</TableCell>
                                        <TableCell>{teamMember.email}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleEdit(index)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(index)}>
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid size={6}>
                        <TextField
                            fullWidth
                            label="First Name"
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            error={!!errors.firstName}
                            helperText={errors.firstName}
                        />
                    </Grid>
                    <Grid size={6}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            variant="outlined"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            error={!!errors.lastName}
                            helperText={errors.lastName}
                        />
                    </Grid>
                    <Grid size={12}>
                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                    <Button
                        onClick={handleAddOrUpdate}
                        sx={{ backgroundColor: '#16697A', color: '#fff', '&:hover': { backgroundColor: '#14566D' } }}
                        color="primary"
                    >
                        {editIndex >= 0 ? "Update" : "Add"}
                    </Button>
                    <Button onClick={handleSave} variant="contained" sx={{ backgroundColor: '#16697A', color: '#fff', '&:hover': { backgroundColor: '#14566D' } }}>
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddTeamMemberPopup;
