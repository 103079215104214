import React, { Fragment, useState, useEffect } from "react";
import WorldMap from "react-svg-worldmap";
import { Box, Button, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import "./CustomWorldMap.css";
import CustomCountryAutoComplete from "./CustomCountryAutoComplete";
import { getCountryIDbycode } from "../utils/ProjectDataHandler";

const CustomWorldMap = (props) => {
    const getCountryISO2 = require("country-iso-3-to-2");
    const getCountryISO3 = require("country-iso-2-to-3");
    const selectedRegionData = props.selectedRegionData;
    const setSelectedRegionData = props.setSelectedRegionData;
    const countries = props.countries;
    const getLocalizedText = (context) => {
        return context.countryName;
    };
    const maxSelections = 5;
    const [exceededLimit, setExceededLimit] = useState(false);

    const stylingFunction = (context) => {
        context.countryValue = context.countryName;
        return {
            fill: isCountrySelected(context.countryCode) ? "#16697a" : "#EDE7E3",
            stroke: "#16697a",
            strokeWidth: 1,
            cursor: "pointer",
        };
    };
    const isCountrySelected = (countryCode) => {
        return selectedRegionData.some((d) => d.country === countryCode);
    };


    const clickAction = (country) => {
        if (
            !isCountrySelected(country.countryCode) &&
            selectedRegionData.length >= maxSelections
        ) {
            setExceededLimit(true);
            return;
        }
        const tempObject = {
            id: getCountryIDbycode(countries, getCountryISO3(country.countryCode)),
            country: country.countryCode,
            countryName: country.countryName,
        };
        if (isCountrySelected(country.countryCode)) {
            setSelectedRegionData((current) =>
                current.filter((data) => {
                    return data.country !== country.countryCode;
                })
            );
        } else {
            setSelectedRegionData([...selectedRegionData, tempObject]);
        }
    };

    const handleClosePopup = () => {
        setExceededLimit(false);
    };

    const onCloseCountry = (countryCode) => {
        setSelectedRegionData((current) =>
            current.filter((data) => {
                return data.country !== countryCode;
            })
        );
    };

    const handleAutoComplete = (value) => {
        const formattedValue = {
            countryCode: getCountryISO2(value.countrycode),
            countryValue: value.name,
            countryName: value.name,
        };

        clickAction(formattedValue);
    };

    return (
        <Fragment>
            <Grid container className="selected-countries">
                <Grid size={{ xs: 4, md: 4, lg: 3 }} className="grid-countries">
                    <CustomCountryAutoComplete
                        title={"countries"}
                        countries={countries}
                        onChange={handleAutoComplete}
                        selectedRegionData={selectedRegionData}
                        onCloseCountry={onCloseCountry}
                    />
                    <h5 className="selected-countries-heading">Selected Countries</h5>
                    <div className="selected-countries-div">
                        <div className="scrollable-content">
                            {selectedRegionData
                                .slice()
                                .sort((a, b) => a.countryName.localeCompare(b.countryName))
                                .map((region) => (
                                    <div className="selected-countries-bar" key={region.country}>
                                        <span className="selected-countries-id">{region.country} </span>
                                        <Button
                                            className="selected-countries-name"
                                            variant="contained"
                                            sx={{
                                                left: "-5px",
                                                padding: "5px 10px 5px 10px",
                                                minHeight: "30px",
                                                maxHeight: "40px",
                                                width: "50%",
                                                color: "#16697a",
                                                backgroundColor: "white",
                                                textTransform: "initial",
                                                fontWeight: 500,
                                                overflow: "hidden",
                                                border: "1px solid #16697a",
                                                borderLeft: 0,
                                                borderRadius: "0 5px 5px 0",
                                                lineHeight: 0.8,
                                                "&:hover": {
                                                    backgroundColor: "white",
                                                    color: "#16697a",
                                                    boxShadow: 5,
                                                },
                                                boxShadow: 0,
                                            }}
                                        >
                                            {region.countryName}
                                            <CloseIcon
                                                sx={{ fontSize: 12, zIndex: 999 }}
                                                className="close-icons"
                                                onClick={() => onCloseCountry(region.country)}
                                            />
                                        </Button>
                                    </div>
                                ))}
                        </div>
                    </div>
                </Grid>
                <Grid size={{ xs: 8, md: 8, lg: 9 }}>
                    <WorldMap
                        backgroundColor=""
                        styleFunction={stylingFunction}
                        data={selectedRegionData}
                        onClickFunction={clickAction}
                        tooltipTextFunction={getLocalizedText}
                        size={'lg'}
                    />
                </Grid>
            </Grid>
            {exceededLimit && (
                <div className="popup">
                    <div className="popup-content">
                        <CloseIcon className="close-icon" onClick={handleClosePopup} />
                        <p>Maximum number of selections reached!</p>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default CustomWorldMap;
