import React, { useState, useContext, useEffect } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, TextField, Link, FormControl, Typography, InputAdornment, IconButton, FormControlLabel, Checkbox, MenuItem, Select } from '@mui/material';
import { cardContext } from '../../hooks/customHooks/Context';
import "./Signup.css";
import { AuthService, ProjectService } from '../../utils/ApiDataHandler';
import englishTC from '../../utils/Terms_Conditions_English.pdf';
import germanTC from '../../utils/Terms_Conditions_German.pdf';



const Signup = ({ onLoginClick }) => {
    const { setHeading, setCardWidth, setTextAlign, setDescription } = useContext(cardContext);
    const [salutations, setSalutations] = useState([]);
    const [selectedSalutation, setSelectedSalutation] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSignUpSuccessful, setIsSignUpSuccessful] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    useEffect(() => {
        setHeading('Sign up for TechScaler');
        setDescription('Create your free account now.');
        setCardWidth("442px");
        setTextAlign("center");
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);

    useEffect(() => {
        fetchSalutations();
    }, []);



    const fetchSalutations = async () => {
        try {
            const data = await ProjectService.fetchSalutations();
            setSalutations(data.data);
        } catch (error) {
            console.error('Error fetching salutations:', error);
        }
    };

    const handleSalutationChange = (event) => {
        setSelectedSalutation(event.target.value);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            const data = await AuthService.signup({
                email,
                salutation_id: selectedSalutation,
                first_name: firstName,
                last_name: lastName,
                password,
            });

            if (data.success) {
                setIsSignUpSuccessful(true);
                setErrorMessage('');
            } else {
                setErrorMessage(data.message || 'Failed to sign up. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An unexpected error occurred. Please try again.');
        }

    };

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const openTermsConditions = () => {
        window.open('/terms-and-conditions', '_blank');
    };
    const openPrivacyPolicy = () => {
        window.open('/privacy-policy', '_blank');
    };
    const termsLink = selectedLanguage === 'de' ? germanTC : englishTC;

    useEffect(() => {
        const isFormValid = email && selectedSalutation && firstName && lastName && password && isTermsAccepted;
        setIsButtonEnabled(isFormValid);
    }, [email, selectedSalutation, firstName, lastName, password, isTermsAccepted]);

    return (
        <Box sx={{ textAlign: 'left' }}>
            {isSignUpSuccessful ? (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="60vh">
                    <Typography variant="p" gutterBottom sx={{ mb: -1, color: '#1c5a73', textAlign: 'center' }}>
                        Thank you for signing up!
                        Please check your email and click on the confirmation link to complete your registration.
                        If you don't see the email in your inbox, please check your spam or junk folder.
                    </Typography>
                    <Box mt={2} display="flex">
                        <Link href="#" variant="h5" onClick={onLoginClick} sx={{ color: '#16697A', textTransform: 'none', '&:hover': { color: '#16697A' } }}>
                            Log in
                        </Link>
                    </Box>
                </Box>
            ) : (
                <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
                    <Box mb={2}>
                        <Typography sx={{ mb: -1 }}>Email (company)*</Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            id="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            fullWidth
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Box>

                    <Box mb={2}>
                        <Typography sx={{ mb: -1 }}>Salutation*</Typography>
                        <FormControl variant="outlined" margin="normal" required>
                            <Select value={selectedSalutation} onChange={handleSalutationChange} displayEmpty
                                sx={{
                                    backgroundColor: 'white',
                                    minWidth: '200px',
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#006a75',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#004e55',
                                    },
                                    '&.Mui-active .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#004e55',
                                    }
                                }}
                            >
                                <MenuItem value="" disabled>Select a salutation</MenuItem>
                                {salutations.map((salutation) => (
                                    <MenuItem key={salutation.id} value={salutation.id}>{salutation.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box mb={2} display="flex" justifyContent="space-between">
                        <Box flex={1} pr={1}>
                            <Typography sx={{ mb: -1 }}>First name*</Typography>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                id="firstName"
                                name="firstName"
                                autoComplete="fname"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Box>
                        <Box flex={1} pl={1}>
                            <Typography sx={{ mb: -1 }}>Last name*</Typography>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                id="lastName"
                                name="lastName"
                                autoComplete="lname"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box mb={2}>
                        <Typography sx={{ mb: -1 }}>Password <span style={{ fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.6)' }}>(Password must be 8 characters long and include a lowercase letter, uppercase letter, digit, and special character(@$!%*?&).)</span>*</Typography>
                        <FormControl variant="outlined" margin="normal" sx={{ backgroundColor: 'white' }}>
                            <TextField
                                fullWidth
                                type={passwordVisible ? 'text' : 'password'}
                                id="password"
                                variant="outlined"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={togglePasswordVisibility}>
                                                {passwordVisible ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box mb={2} display="flex" alignItems="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#1c5a73" }}
                                    value="termsAccepted"
                                    color="primary"
                                    required
                                    onChange={(e) => setIsTermsAccepted(e.target.checked)}
                                />
                            }
                            label={
                                <span>
                                    I agree with{' '}
                                    <Link
                                        underline="hover"
                                        sx={{ color: '#16697A', '&:hover': { color: '#16697A' } }}
                                        target="_blank"
                                        onClick={openTermsConditions}
                                    >
                                        Terms and Conditions
                                    </Link>
                                    {' '}and{' '}
                                    <Link
                                        underline="hover"
                                        sx={{ color: '#16697A', '&:hover': { color: '#16697A' } }}
                                        onClick={openPrivacyPolicy}
                                        target="_blank"
                                    >
                                        Privacy Policy
                                    </Link>

                                </span>
                            }
                        />
                        { /* <FormControl sx={{ minWidth: 120, ml: 2 }}>
                            <Select
                                value={selectedLanguage}
                                onChange={handleLanguageChange}
                                sx={{
                                    backgroundColor: 'white',
                                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#006a75' },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#004e55' },
                                }}
                            >
                                <MenuItem value="en">English</MenuItem>
                                <MenuItem value="de">German</MenuItem>
                            </Select>
                        </FormControl>*/}
                    </Box>

                    <Box mt='-20px'>
                        <FormControlLabel
                            control={<Checkbox style={{ color: "#1c5a73" }} value="remember" color="primary" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />}
                            label="Remember me"
                        />
                    </Box>

                    <Box mt={2}>
                        <Button type="submit" variant="contained" sx={{ bgcolor: '#16697A', color: '#ffffff', textTransform: 'none', '&:hover': { bgcolor: '#16697A' } }} disabled={!isButtonEnabled}>
                            Sign Up
                        </Button>
                    </Box>

                    {errorMessage && (
                        <Typography color="error" mt={2}>
                            {errorMessage}
                        </Typography>
                    )}

                    <Box mt={2}>
                        <Link href="#" variant="body2" onClick={onLoginClick} underline="none" sx={{ color: '#16697A', '&:hover': { color: '#16697A' } }}>
                            Already have an account? Log in
                        </Link>
                    </Box>
                </form>
            )}
        </Box>
    );
};

export default Signup;
